import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, SemanticCOLORS } from 'semantic-ui-react'
import { Table, Label } from "semantic-ui-react";

interface IRisklevel {
  range: string
  classification: string
  color: SemanticCOLORS
}

const RiskValueTable = () => {
  const { t } = useTranslation()
  const riskLevels: IRisklevel[] = [
    { range: "1-25", classification: t('riskLevel0'), color: "blue" },
    { range: "(-)1-2", classification: t('riskLevel1'), color: "green" },
    { range: "(-)3-4", classification: t('riskLevel2'), color: "teal" },
    { range: "(-)5-9", classification: t('riskLevel3'), color: "yellow" },
    { range: "(-)10-14", classification: t('riskLevel4'), color: "orange" },
    { range: "(-)15-25", classification: t('riskLevel5'), color: "red" },
  ];
  return (
    <div style={{ marginBottom: "2em" }}>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Table celled compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('riskValue')}</Table.HeaderCell>
                <Table.HeaderCell>{t('riskClassification')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {riskLevels.map((risk, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{risk.range}</Table.Cell>
                  <Table.Cell>
                    <Label color={risk.color} circular empty /> {risk.classification}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default RiskValueTable
