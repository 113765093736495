import React from 'react'
import { Routes, Route } from 'react-router'
import { Grid } from 'semantic-ui-react'
import { isInPageRenderMode } from '../../../actions/util'
import RiskMenu from './RiskMenu'
import Loading from '../../common/Loading'
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams'
import * as API from '../../../api'
import RiskList from './RiskList'
import RiskFormPage from './RiskFormPage'

const RisksModule = () => {
    const { isLoading, data: risks, refresh } = useGetQueryByParams(true, API.risks.getAll, "riskList")
    const { isLoading: isLoadingLimits, data: limits, } = useGetQueryByParams(true, API.risks.getLimits, "riskLimits")
    const { isLoading: isLoadingRiskTypes, data: riskTypes } = useGetQueryByParams(true, API.risks.getAllRiskTypes, "riskTypes")
    if (isLoading || isLoadingLimits || isLoadingRiskTypes || !risks || !riskTypes) {
        return <Loading />
    }
    return (
        <Grid
            stackable
            columns={isInPageRenderMode() ? 2 : 1}
            style={{ minHeight: '100%', flexGrow: 1 }}
        >
            <Grid.Row>
                {isInPageRenderMode() && (
                    <Grid.Column tablet={16} largeScreen={4} widescreen={3}>
                        <RiskMenu />
                    </Grid.Column>
                )}
                <Grid.Column
                    tablet={16}
                    largeScreen={isInPageRenderMode() ? 12 : 16}
                    widescreen={isInPageRenderMode() ? 13 : 16}
                >
                    <Routes>
                        <Route index element={<RiskList risks={risks} limits={limits} riskTypes={riskTypes} isLoading={isLoading} refresh={refresh} />} />
                        <Route path="/detail/">
                            <Route path="new" element={<RiskFormPage riskTypes={riskTypes} isNew refresh={refresh} />} />
                            <Route path={`:riskId`} element={<RiskFormPage riskTypes={riskTypes} isNew={false} refresh={refresh} />} />
                        </Route>
                        <Route path="/emergency/">
                            <Route index element={<h1>emergency</h1>} />
                            <Route path="new" element={<h1>new</h1>} />
                            <Route path={`:emergencyId`} element={<h1>emergencyId</h1>} />
                        </Route>
                    </Routes>

                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default RisksModule
