import { get, destroy, post, put } from "../restClient";

export interface IRiskDto {
    id: number
    riskType: number
    documentId: number
    risk: string
    apt: string
    impact: string
    measurment: string
    comment: string
    consequence: number
    probability: number
    consequenceProbabilityValue: number
    routine: boolean
    latestChange: Date
}

export interface IRiskListResponse extends IRiskDto {
    riskTypeName: string
    documentName: string
    hasGoal: boolean
    hasActionPlan: boolean
    hasLaw: boolean
    hasRoutine: boolean
}
export interface IRiskTypeDto {
    id: number
    name: string
}

export interface IRiskGoalDto {
    id: number
    name: string
    goalId: number
    riskId: number
}

export interface IRiskDocumentDto {
    id: number
    title: string
    documentId: number
    riskId: number
}

export interface IRiskLawDto {
    id: number
    name: string
    lawId: number
    riskId: number
    designation: string
}

export interface IRiskAssessmentDto {
    id: number
    comment: string
    consequence: number
    probability: number
    consequenceProbabilityValue: number
    latestUpdate: Date | null
}
export interface IRiskActionPlanDto {
    id: number;
    status: number
    companyId: number | null;
    riskId: number | null;
    responsibleId: string;
    topic: string;
    description: string;
    doneBefore: Date | null;
    useReview: boolean | null;
    reviewDate: Date | null;
    reviewResponsibleId: string;
    reviewText: string;
    endedDate: Date | null;
    endedBy: string;
}

export interface IRiskLimitDto {
    id: number;
    positive: number | null;
    negative: number | null;
}

export interface IRiskStatisticMeasurmentDto {
    id: number;
    periodMeasurement: string;
    detailedType: string;
    unit: string;
    inRelation: string;
    inRelationUnit: string;
    matId: number | null
    mafId: number | null
}

export interface IMeasurementTypeDto {
    id: number;
    detailedType: string;
}

export interface IMeasurementRelationTypeDto {
    id: number;
    inRelation: string;
}

export interface IRiskStatisticMeasurementDataDto {
    id: number;
    matId: number | null;
    mafId: number | null;
    year: number | null;
    monthWeek: string;
    value: number | null;
    comparisonValue: number | null;
}

export const getAll = () => get<IRiskListResponse[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk`)

export const getById = (id: number) => get<IRiskDto>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${id}`)

export const createRisk = (data: IRiskDto) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk`, data)

export const editRisk = (riskId: number, data: IRiskDto) => put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${riskId}`, data)

export const deleteRisk = (id: number) => destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${id}`)

export const getAllRiskTypes = () => get<IRiskTypeDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/types`)

export const getAllRiskAsessments = (id: number) => get<IRiskAssessmentDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/assessments/${id}`)

export const createRiskAsessment = (riskId: number, data: IRiskAssessmentDto) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${riskId}/assessments`, data)

export const editRiskAsessment = (riskId: number, assessmentId: number, data: IRiskAssessmentDto) => put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${riskId}/assessments/${assessmentId}`, data)

export const deleteRiskAsessment = (riskId: number, assessmentId: number) => destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${riskId}/assessments/${assessmentId}`)

export const getAllRiskGoals = (id: number) => get<IRiskGoalDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${id}/goals`)

export const createRiskGoal = (riskId: number, goalId: number) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${riskId}/goals/${goalId}`)

export const deleteRiskGoal = (riskId: number, goalId: number) => destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${riskId}/goals/${goalId}`)

export const getAllRiskDocuments = (id: number) => get<IRiskDocumentDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${id}/documents`)

export const createRiskDocument = (riskId: number, documentId: number) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${riskId}/documents/${documentId}`)

export const deleteRiskDocument = (riskId: number, documentId: number) => destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${riskId}/documents/${documentId}`)

export const getAllRiskLaws = (id: number) => get<IRiskLawDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${id}/laws`)

export const createRiskLaw = (riskId: number, lawId: number) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${riskId}/laws/${lawId}`)

export const deleteRiskLaw = (riskId: number, lawId: number) => destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/${riskId}/laws/${lawId}`)

export const getActionPlans = (id: string) => get<IRiskActionPlanDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/${id}/action-plan`)

export const createActionPlan = (id: number, data: IRiskActionPlanDto) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/${id}/action-plan`, data)

export const editActionPlan = (id: number, data: IRiskActionPlanDto) => put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/${id}/action-plan`, data)

export const destroyActionPlan = (id: number) => destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/action-plan/${id}`)

export const remindActionPlan = (riskId: number, actionPlanId: number) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/${riskId}/action-plan/${actionPlanId}/remind`)

export const getLimits = () => get<IRiskLimitDto>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Risk/limit`)

export const getRiskStatisticMeasurment = (id: string) => get<IRiskStatisticMeasurmentDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/${id}/statistic/measurement`)

export const getRiskStatisticMeasurmenData = (data: { riskId: string, id: string }) => get<IRiskStatisticMeasurementDataDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/${data.riskId}/statistic/measurement/${data.id}/data`)

export const destroyRiskStatisticMeasurment = (riskId: number, id: number) => destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/${riskId}/statistic/measurement/${id}`)

export const createRiskStatisticMeasurment = (id: number, data: IRiskStatisticMeasurmentDto) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/${id}/statistic/measurement`, data)

export const editRiskStatisticMeasurment = (id: number, data: IRiskStatisticMeasurmentDto) => put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/${id}/statistic/measurement/${data.id}`, data)

export const getRiskStatisticMeasurmentTypes = () => get<IMeasurementTypeDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/statistic/measurement/types`)

export const getRiskStatisticMeasurmentRelations = () => get<IMeasurementRelationTypeDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/risk/statistic/measurement/relation`)
