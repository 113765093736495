import React, { useMemo, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../api'
import Loading from '../../../../common/Loading'
import { createColumnHelper, getCoreRowModel, getExpandedRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, Row, useReactTable } from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { ChevronDown, ChevronRight, Edit, Trash2 } from 'react-feather'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import TableReact from '../../../../wrappers/TableReact'
import ModalWrapper from '../../../../wrappers/ModalWrapper'
import RiskStatisticMeasurementForm from '../RiskStatisticMeasurementForm'
import { useDeleteRiskStatisticMeasurment } from './useDeleteRiskStatisticMeasurment'
import { IRiskStatisticMeasurmentDto } from '../../../../../api/risks'
import RiskStatisticExpanded from './RiskStatisticExpanded'


interface IRiskStatisticMeasurmentListProps {
    riskId: number
}
const RiskStatisticMeasurmentList = ({ riskId }: IRiskStatisticMeasurmentListProps) => {
    const { t } = useTranslation()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, API.risks.getRiskStatisticMeasurment, "riskStatisticMeasurmentList", riskId)

    const [showAddModal, setShowAddModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedStatisticMeasurement, setSelectedStatisticMeasurement] = useState<IRiskStatisticMeasurmentDto | null>(null);
    const { isDestroying, onDestroy } = useDeleteRiskStatisticMeasurment(refresh)

    const onCancel = () => {
        setOpen(false)
        setSelectedStatisticMeasurement(null)
    }

    const handleSetSelecteKeyFigure = (keyFigure: IRiskStatisticMeasurmentDto) => {
        setOpen(true)
        setSelectedStatisticMeasurement(keyFigure)
    }

    const columnHelper = createColumnHelper<IRiskStatisticMeasurmentDto>()
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: "expander",
                enableHiding: false,
                size: 20,
                meta: { checkbox: true },
                cell: ({ row }) => {
                    return row.getCanExpand() ? (
                        <SemanticFeatherIcon onClick={row.getToggleExpandedHandler()} FeatherIcon={row.getIsExpanded() ? ChevronDown : ChevronRight} />
                    ) : null
                },
            }),
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                size: 75,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' disabled={isDestroying} onClick={() => handleSetSelecteKeyFigure(row.original)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        <Button title={t('delete')} disabled={isDestroying} onClick={() => onDestroy({
                            confirmText: t('confirm.deleteValue', { value: row.original.periodMeasurement }),
                            id: row.original.id,
                            riskId: riskId
                        })}
                            type='button'
                            color='red'
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />

                    </>
            }),
            columnHelper.accessor("periodMeasurement", {
                id: "periodMeasurement",
                header: t('periodMeasurement') as any,
            }),
            columnHelper.accessor("detailedType", {
                id: "detailedType",
                header: t('detailedType') as any,
            }),
            columnHelper.accessor("unit", {
                id: "unit",
                header: t('unit') as any,
            }),
            columnHelper.accessor("inRelation", {
                id: "inRelation",
                header: t('inRelation') as any,
            }),
            columnHelper.accessor("inRelationUnit", {
                id: "inRelationUnit",
                header: t('inRelationUnit') as any,
            }),


        ], [columnHelper, t, isDestroying, onDestroy, riskId])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: columns,
        enableGlobalFilter: false,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: () => true
    })

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <FullHeightStackingContainer>
            <TableReact table={table}
                onAddCallback={() => setShowAddModal(true)}
                overflowable
                canExport={false}
                renderSubComponent={(row: Row<any>) => <RiskStatisticExpanded riskId={riskId} row={row} />} />

            <ModalWrapper title={t('addValue', { value: t('actionPlan').toLowerCase() })} open={showAddModal} onClose={() => setShowAddModal(false)} size='small'>
                <RiskStatisticMeasurementForm onCancel={() => setShowAddModal(false)} refresh={refresh} isNew riskId={riskId} />
            </ModalWrapper>

            {selectedStatisticMeasurement && <ModalWrapper title={t('editValue', { value: t('actionPlan').toLowerCase() })} open={open} onClose={() => onCancel()} size='small'>
                <RiskStatisticMeasurementForm onCancel={() => onCancel()} refresh={refresh} isNew={false} riskId={riskId} selectedMeasurement={selectedStatisticMeasurement} />
            </ModalWrapper>}

        </FullHeightStackingContainer>
    )
}

export default RiskStatisticMeasurmentList
