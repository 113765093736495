import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Tab, TabPane } from 'semantic-ui-react';
import { IRiskDto, IRiskTypeDto } from '../../../api/risks';
import { IDocumentIndex } from '../../../api/documents';
import RiskGoalTab from './RiskGoalTab';
import RiskDocumentTab from './RiskDocumentTab';
import RiskLawTab from './RiskLawTab';
import RiskActionPlanList from './RiskActionPlanTab/RiskActionPlanList';
import RiskInformationTab from './RiskInformationTab';
import RiskStatisticMeasurmentList from './RiskStatisticMeasurmentTab/RiskStatisticMeasurmentList';

interface IRiskFormProps {
    isNew: boolean
    refresh: () => void
    onCancel: () => void
    riskTypes: IRiskTypeDto[]
    documents: IDocumentIndex
    selectedRisk?: IRiskDto
}

const RiskForm = ({ riskTypes, documents, refresh, isNew, selectedRisk, onCancel }: IRiskFormProps) => {
    const { t } = useTranslation()
    const showTab = !isNew && selectedRisk
    const panes = useMemo(() => [
        { menuItem: t("risks"), render: () => <TabPane><RiskInformationTab riskTypes={riskTypes} documents={documents} refresh={refresh} risk={selectedRisk} onCancel={onCancel} /></TabPane> },
        showTab ? { menuItem: t("goal"), render: () => <TabPane><RiskGoalTab riskId={selectedRisk.id} /></TabPane> } : {},
        showTab ? { menuItem: t("actionPlan"), render: () => <TabPane><RiskActionPlanList riskId={selectedRisk.id} /></TabPane> } : {},
        showTab ? { menuItem: t("routine"), render: () => <TabPane><RiskDocumentTab riskId={selectedRisk.id} /></TabPane> } : {},
        showTab ? { menuItem: t("laws"), render: () => <TabPane><RiskLawTab riskId={selectedRisk.id} /></TabPane> } : {},
        showTab ? { menuItem: t("statistics"), render: () => <TabPane><RiskStatisticMeasurmentList riskId={selectedRisk.id}/></TabPane> } : {},
    ], [t, refresh, selectedRisk, showTab, documents, riskTypes, onCancel])

    return <Container><Tab panes={panes} /></Container>
};

export default RiskForm;
