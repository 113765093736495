import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Header } from 'semantic-ui-react'
import { ColumnDef, createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../../../icons/SemanticFeatherIcon'
import { Edit, Trash2 } from 'react-feather'
import { formatDate } from '../../../../../../actions/util'
import DateCell from '../../../../../common/cells/DateCell'
import TableReact from '../../../../../wrappers/TableReact'
import { IRiskAssessmentDto } from '../../../../../../api/risks'
import { useGetQueryByParams } from '../../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../../api'
import Loading from '../../../../../common/Loading'
import ModalWrapper from '../../../../../wrappers/ModalWrapper'
import RiskAssessmentForm from '../RiskAssessmentForm'
import { useIsInRole } from '../../../../../../hooks/role'
import { AdminRoles } from '../../../../../../auth/roles'
import { useDeleteRiskAssesment } from './useDeleteRiskAssesment'

interface IRiskAssessmentListProps {
    riskId: number
}
const RiskAssessmentList = ({ riskId }: IRiskAssessmentListProps) => {
    const { t } = useTranslation()
    const { isLoading, data, refresh } = useGetQueryByParams(!!riskId, API.risks.getAllRiskAsessments, "riskAssessments", riskId)
    const columnHelper = createColumnHelper<IRiskAssessmentDto>()
    const [open, setOpen] = useState(false);
    const [selectedAssessment, setSelectedAssessment] = useState<IRiskAssessmentDto | null>(null);
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.RisksAdminRole)
    const { isDestroying, onDestroy } = useDeleteRiskAssesment(refresh)

    const onCancel = () => {
        setOpen(false)
        setSelectedAssessment(null)
    }

    const handleSetSelectedAssessment = (activity: IRiskAssessmentDto | null) => {
        setOpen(true)
        setSelectedAssessment(activity)
    }

    const riskAssessmentColumns: ColumnDef<IRiskAssessmentDto, any>[] = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 50,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' onClick={() => handleSetSelectedAssessment(row.original)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} disabled={isDestroying} />
                        {isAdmin && <Button
                            title={t('delete')}
                            onClick={() => onDestroy({ confirmText: t('confirm.deleteValue', { value: row.original.comment?.split(" ").slice(0, 5).join(" ") }), riskId: riskId, assessmentId: row.original.id })}
                            type='button'
                            color='red'
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />}
                    </>
            }),
            columnHelper.accessor('comment', {
                id: "comment",
                header: t('comment'),
            }),
            columnHelper.accessor("consequence", {
                id: "consequence",
                header: t('consequence'),
                size: 80
            }),
            columnHelper.accessor("probability", {
                id: "probability",
                header: t('probability'),
                size: 80
            }),
            columnHelper.accessor("consequenceProbabilityValue", {
                id: "consequenceProbabilityValue",
                header: t('consequenceProbabilityValue'),
                enableColumnFilter: false,
                size: 80
            }),
            columnHelper.accessor(r => r.latestUpdate ? formatDate(r.latestUpdate) : "", {
                id: "latestUpdate",
                header: t('latestUpdate'),
                cell: ({ row }) => <DateCell date={row.original.latestUpdate} />
            }),

        ], [columnHelper, t, riskId, isDestroying, onDestroy, isAdmin])
    const table = useReactTable({
        data: data || [],
        columns: riskAssessmentColumns,
        columnResizeMode: "onChange",
        enableGlobalFilter: false,
        enableGrouping: false,
        enableColumnFilters: false,
        initialState: {
            sorting: [{ id: "latestUpdate", desc: true }],
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })
    if (isLoading || !data) {
        return <Loading />
    }
    return (
        <>
            <Header dividing content={t('assessments')} />
            <TableReact table={table}
                onAddCallback={() => handleSetSelectedAssessment(null)}
                canExport={false}
                overflowable
            />
            <ModalWrapper title={t('registerNew')} open={open} onClose={onCancel}>
                <RiskAssessmentForm riskId={riskId} selectedAssessment={selectedAssessment} onCancel={onCancel} refresh={refresh} />
            </ModalWrapper>
        </>
    )
}

export default RiskAssessmentList
