import { post, put, get, destroy } from "../restClient";
import { UserLanguageType } from "../types/Enum";

export interface IClothesSizeDto {
    userId: string,
    pantSize: string,
    shirtSize: string,
    shoeSize: string,
    gloveSize: string
}

export interface IUserIce {
    userId: string,
    userIce1: string,
    userIce2: string
}

export interface IChangePasswordRequest {
    oldPassword: string,
    newPassword: string
}

export interface IDepartmentDto {
    id: number,
    name: string,
    description: string,
    isUsed: boolean
}

export interface ILocationDto {
    id: number,
    name: string,
    description: string,
    isUsed: boolean
}

export interface IUserAccountDto {
    id: string,
    name: string,
    username: string,
    email: string,
    phone: string,
    language: UserLanguageType,
    latestTokenIssued: Date
}

export const getMyIce = () => get<IUserIce>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/userIce`)

export const getIceList = () => get<IUserIce[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/iceList`)

export const adminUpdateUserIce = (userId: string, data: IUserIce) =>put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/userIce/${userId}`, data)

export const createIce = (userId: string | undefined, data: IUserIce) =>post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/userIce/${userId}`, data)

export const changePassword = (data: IChangePasswordRequest) =>post(`${window.env.REACT_APP_AUTH_SERVICE}/api/account/password/change`, data)

export const adminUpdateClothesSize = (userId: string, data: IClothesSizeDto) =>put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/clothesSize/${userId}`, data) 

export const createClothesSize = (userId: string | undefined, data: IClothesSizeDto) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/clothesSize/${userId}`, data)

export const getClothesSizeList = () => get<IClothesSizeDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/clothesList`)

export const getMyClothesSize = () => get<IClothesSizeDto>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/UserInformation/clothesSize`)

export const getMyDepartments = () => get<IDepartmentDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Department/my`)

export const getDepartmentList = (companyId: number) => get<IDepartmentDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Department/${companyId}`)

export const adminUpdateDepartment = (departmentId: number, data: IDepartmentDto) =>put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Department/${departmentId}`, data)

export const createDepartment = (data: IDepartmentDto) =>post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Department/`, data)

export const deleteDepartment = (departmentId: number) =>destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Department/${departmentId}`)

export const getMyLocations = () => get<ILocationDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Location/my`)

export const getLocationList = (locationId: number) => get<ILocationDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Location/${locationId}`)

export const adminUpdateLocation = (locationId: number, data: ILocationDto) =>put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Location/${locationId}`, data)

export const createLocation = (data: ILocationDto) =>post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Location/`, data)

export const deleteLocation = (locationId: number) =>destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Location/${locationId}`)

// export const getUserAccounts = () => get<IUserAccountDto[]>(`${window.env.REACT_APP_AUTH_SERVICE}/api/account/Info/users`)

export const getMyUserAccounts = () => get<IUserAccountDto[]>(`${window.env.REACT_APP_AUTH_SERVICE}/api/account/Info/my`) 

export const deleteUserAccount = (accountId: string) =>destroy(`${window.env.REACT_APP_AUTH_SERVICE}/api/account/${accountId}`)

export const adminUpdateUserAccount = (accountId: string, data: IUserAccountDto) =>put(`${window.env.REACT_APP_AUTH_SERVICE}/api/account/${accountId}`, data) // rätt?

export const createUserAccount = (data: IUserAccountDto) =>post(`${window.env.REACT_APP_AUTH_SERVICE}/api/account/`, data) // rätt?