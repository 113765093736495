import React, { useEffect } from "react";
import { Button, Form, Grid, GridColumn, GridRow } from "semantic-ui-react";
import ControlInput from "../../fields/ControlInput";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import SemanticFeatherIcon from "../../icons/SemanticFeatherIcon";
import { Save } from "react-feather";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { handleAxiosError } from "../../../actions/error";
import * as API from '../../../api';
import { getState } from "../../../state";
import { IClothesSizeDto } from "../../../api/admin";
import { z } from "zod";

interface ClothesSizeFormProps {
    selectedUser: IClothesSizeDto | null;
    refresh: () => void;
    onClose?: () => void;
}

const clothesSizeListSchema = z.object({
    userId: z.string().optional(),
    pantSize: z.string().optional(),
    shirtSize: z.string().optional(),
    shoeSize: z.string().optional(),
    gloveSize: z.string().optional(),
});

const ClothesSizeForm : React.FC<ClothesSizeFormProps> = ({ selectedUser, refresh, onClose }) => {
    const { t } = useTranslation();
    const {
            dispatch,
        } = getState()

        const { control, handleSubmit, reset } = useForm<IClothesSizeDto>({
            resolver: zodResolver(clothesSizeListSchema),
        });

    useEffect(() => {
        if (selectedUser) {
            reset({
                pantSize: selectedUser.pantSize ?? "",
                shirtSize: selectedUser.shirtSize ?? "",
                shoeSize: selectedUser.shoeSize ?? "",
                gloveSize: selectedUser.gloveSize ?? "",
            });
        } else {
            reset({ pantSize: "", shirtSize: "", shoeSize: "", gloveSize: "" });
        }
    }, [selectedUser, reset]);

    const onClothesSizeRequest = async (data: IClothesSizeDto) => {
        if (selectedUser?.userId) {
            return await API.admin.adminUpdateClothesSize(selectedUser.userId, data);
        } else {
            return await API.admin.createClothesSize(selectedUser?.userId, data);
        }
      };
  
      const { isLoading: isSaving, mutate: onSubmit } = useMutation(onClothesSizeRequest, {
          onError: (error: any) => {
              handleAxiosError(dispatch)(error);
          },
          onSuccess: () => {
              toast.success(t('saved'));
              refresh();
              if (onClose) onClose();
          },
      });

      const onSubmitHandler: SubmitHandler<IClothesSizeDto> = (data) => {
        onSubmit(data);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                <Grid stackable>
                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="pantSize" label={t('pantSize')} placeholder={t('pantSize')} control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="shirtSize" label={t('shirtSize')} placeholder={t('shirtSize')} control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="shoeSize" label={t('shoeSize')} placeholder={t('shoeSize')} control={control} disabled={false} />
                        </GridColumn>
                    </GridRow>
                
                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="gloveSize" label={t('gloveSize')} placeholder={t('gloveSize')} control={control} disabled={false} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1" textAlign='right'>
                        <GridColumn >
                            <Button
                                type="submit" 
                                primary 
                                disabled={false}
                                icon 
                                labelPosition="left">
                                <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon />
                                {t('save')}
                            </Button>
                        </GridColumn>
                    </GridRow>

                </Grid>
            </Form>
    );
};

export default ClothesSizeForm;