import { IRiskActionPlanDto, IRiskAssessmentDto, IRiskStatisticMeasurmentDto } from "../../../api/risks";

export const mapRiskAssessment = (assessment: IRiskAssessmentDto): IRiskAssessmentDto => ({
    id: assessment.id,
    comment: assessment.comment,
    consequence: assessment.consequence,
    probability: assessment.probability,
    consequenceProbabilityValue: assessment.consequenceProbabilityValue,
    latestUpdate: assessment.latestUpdate ? new Date(assessment.latestUpdate) : null,
})

export const mockedRiskAssessment: IRiskAssessmentDto = {
    id: -1,
    comment: "",
    consequence: 5,
    probability: 3,
    consequenceProbabilityValue: 0,
    latestUpdate: new Date(),
}

export const mapRiskActionPlanToRequest = (actionPlan: IRiskActionPlanDto): IRiskActionPlanDto => ({
    id: actionPlan.id,
    status: actionPlan.status,
    companyId: actionPlan.companyId,
    riskId: actionPlan.riskId,
    responsibleId: actionPlan.responsibleId,
    topic: actionPlan.topic,
    description: actionPlan.description,
    useReview: actionPlan.useReview || false,
    doneBefore: actionPlan.doneBefore ? new Date(actionPlan.doneBefore) : null,
    reviewDate: actionPlan.reviewDate ? new Date(actionPlan.reviewDate) : null,
    endedDate: actionPlan.endedDate ? new Date(actionPlan.endedDate) : null,
    reviewResponsibleId: actionPlan.reviewResponsibleId,
    reviewText: actionPlan.reviewText,
    endedBy: actionPlan.endedBy
});

export const mockedRiskActionPlan: IRiskActionPlanDto = {
    id: 0,
    responsibleId: "",
    topic: "",
    description: "",
    useReview: false,
    doneBefore: null,
    reviewDate: null,
    endedDate: null,
    reviewResponsibleId: "",
    reviewText: "",
    endedBy: "",
    status: 0,
    companyId: null,
    riskId: null
}
export const mockedRiskStatisticMeasurment: IRiskStatisticMeasurmentDto = {
    id: -1,
    periodMeasurement: "",
    detailedType: "",
    unit: "",
    inRelation: "",
    inRelationUnit: "",
    mafId: null,
    matId: null
}