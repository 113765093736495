import React from "react";
import { Form, Grid, GridColumn, GridRow } from "semantic-ui-react";
import ControlInput from "../../fields/ControlInput";
import FormActions from "../FormActions";
import { ILocationDto } from "../../../api/admin";
import { z } from "zod";
import { getState } from "../../../state";
import { useTranslation } from "react-i18next";
import * as API from '../../../api';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "react-query";
import { handleAxiosError } from "../../../actions/error";
import { toast } from "react-toastify";
import ControlTextArea from "../../fields/ControlTextArea";

interface LocationFormProps {
    selectedLocation: ILocationDto | null;
    refresh: () => void;
    onClose: () => void;
}

const LocationListSchema = z.object({
    name: z.string().optional(),
    description: z.string().optional(),
});

const LocationForm: React.FC<LocationFormProps> = ({ selectedLocation, refresh, onClose }) => {

    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()

    const { control, handleSubmit } = useForm<ILocationDto>({
        resolver: zodResolver(LocationListSchema),
        defaultValues: selectedLocation ? selectedLocation : { name: "", description: "" }
    });

    const onLocationRequest = async (data: ILocationDto) => {
        if (selectedLocation?.id) {
            return await API.admin.adminUpdateLocation(selectedLocation.id, data);
        } else {
            return await API.admin.createLocation(data);
        }
    };

    const { isLoading: isSaving, mutate } = useMutation(onLocationRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error);
        },
        onSuccess: () => {
            toast.success(t('saved'));
            refresh();
            onClose();
        },
    });

    return (
        <Form onSubmit={handleSubmit((data) => mutate(data))} noValidate >
            <Grid stackable>

                <GridRow columns="1">
                    <GridColumn>
                        <ControlInput name="name" label={t('location')} placeholder={t('location')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <ControlTextArea name="description" label={t('description')} placeholder={t('description')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <FormActions onCancel={onClose} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

            </Grid>
        </Form>
    )
};

export default LocationForm;