import * as z from 'zod';
import { IExtraField } from '../../api/issues';

export const forbiddenChars = /[."'[\]\\:/;,&_)]/;
export const allowedUserNameRegex = /^[a-öA-Ö0-9\-._@+\s]+$/;

export const IExtraFieldDtoSchema = z.object({
    value: z.any().nullable(),
    type: z.number(),
    required: z.boolean(),
}).refine((data) => {
    if (data.required) {
        if (data.value == null) return false;
        if (typeof data.value === 'string' && data.value.trim() === '') return false;
        if (Array.isArray(data.value) && data.value.length === 0) return false;

    }
    return true;
}, {
    message: "required",
    path: ["value"],
});

export const generateExtraFieldsSchema = (extraFields: { [key: string]: IExtraField }) => {
    const schemaObject: { [key: string]: any } = {};
    for (const key in extraFields) {
        const field = extraFields[key];
        if (field.required) {
            schemaObject[key] = z.string().min(1, "required"); // Customize error message as needed
        } else {
            schemaObject[key] = z.string().optional();
        }
    }
    return z.object(schemaObject);
};