import * as API from "../../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { handleAxiosError } from "../../../../actions/error"
import { getState } from "../../../../state"

interface IDestroyDepartment {
    id: number
    confirmText: string
}

export function useDestroyDepartment(refresh: () => void) {
    const { t } = useTranslation()
    const { dispatch } = getState()

    const destroyDepartment = async (data: IDestroyDepartment) => {
        if (window.confirm(data.confirmText)) {
            return await API.admin.deleteDepartment(data.id)
        }
    }

    const { isLoading: isDestroying, mutate: onDestroy } = useMutation(destroyDepartment, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast.success(t('saved'))
                refresh()
            }
        },
    })

    return {
        isDestroying,
        onDestroy,
    }
}