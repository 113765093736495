import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, Grid, Header, Table } from 'semantic-ui-react'
import ReactToPrint from 'react-to-print'
import SemanticFeatherIcon from '../../../../../icons/SemanticFeatherIcon'
import { Printer } from 'react-feather'
import DocumentSectionModule from '../../../../docs/viewPage/DocumentSectionModule'
import { IPositionUserDto, ISkillsDto } from '../../../../../../api/skills'
import PrintWrapper from '../../../../../wrappers/PrintWrapper'

interface IPositionPrintViewProps {
    selectedPosition: any
}
const PositionPrintView = ({ selectedPosition }: IPositionPrintViewProps) => {
    const { t } = useTranslation()
    const printRef = useRef(null)

    return (
        <Container>
            <Grid stackable>
                <Grid.Row columns={1} >
                    <Grid.Column textAlign='right'>
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    type="button"
                                    icon={<SemanticFeatherIcon FeatherIcon={Printer} />}
                                    content={t('print')}
                                />
                            )}
                            content={() => printRef.current!}
                            pageStyle="padding: 20px;"
                        />
                    </Grid.Column>
                </Grid.Row>
                <div ref={printRef}>
                    <PrintWrapper title={t('jobDescription')}>
                        <div className="show-in-print">
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Header as="h1" textAlign="center" style={{ marginBottom: "1rem" }}>
                                        {selectedPosition.label}
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        </div>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: selectedPosition.description || "",
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <div className={selectedPosition.requiredSkills?.length === 0 ? "hide-in-print" : ''}>
                                    <DocumentSectionModule title={t('requiredSkills')}>
                                        <Table unstackable basic="very">
                                            <Table.Body>
                                                {selectedPosition.requiredSkills?.map((x: ISkillsDto) => (
                                                    <Table.Row key={x.id}>
                                                        <Table.Cell>{x.name}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </DocumentSectionModule>
                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <div className={selectedPosition.users?.length === 0 ? "hide-in-print" : ''}>
                                    <DocumentSectionModule title={t('users')}>
                                        <Table unstackable basic="very">
                                            <Table.Body>
                                                {selectedPosition.users?.map((x: IPositionUserDto) => (
                                                    <Table.Row key={x.id}>
                                                        <Table.Cell>{x.name}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </DocumentSectionModule>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </PrintWrapper>
                </div>
            </Grid>

        </Container>
    )
}

export default PositionPrintView
