import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow, Message } from 'semantic-ui-react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IRiskAssessmentDto } from '../../../../../../api/risks';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../../../state';
import { mapRiskAssessment, mockedRiskAssessment } from '../../../../../../utils/Mappings/Risk';
import ControlDateField from '../../../../../fields/ControlDateField';
import ControlTextArea from '../../../../../fields/ControlTextArea';
import ControlInput from '../../../../../fields/ControlInput';
import FormActions from '../../../../FormActions';
import { handleAxiosError } from '../../../../../../actions/error';
import * as API from '../../../../../../api'
import ConsequenceProbabilityTable from './ConsequenceProbabilityTable';

export interface IRiskAssessmentFormProps {
    riskId: number
    onCancel: () => void
    selectedAssessment: IRiskAssessmentDto | null
    refresh: () => void
}

export const riskAssessmentRequestSchema = z.object({
    comment: z.string().nullable(),
    consequence: z.coerce.number().min(-5, "consequenceValueError").max(5, "consequenceValueError"),
    probability: z.coerce.number().min(1, "probabilityValueError").max(5, "probabilityValueError"),
    latestUpdate: z.date().nullable(),
});

const RiskAssessmentForm = ({ riskId, onCancel, selectedAssessment, refresh }: IRiskAssessmentFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit, setValue, watch } = useForm<IRiskAssessmentDto>({
        defaultValues: selectedAssessment ? mapRiskAssessment(selectedAssessment) : mockedRiskAssessment,
        resolver: zodResolver(riskAssessmentRequestSchema),
    });

    const addActivity = async (data: IRiskAssessmentDto) => {
        if (selectedAssessment) {
            return await API.risks.editRiskAsessment(riskId, selectedAssessment.id, data)
        } else {
            return await API.risks.createRiskAsessment(riskId, data)
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(addActivity, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })
    const handleConsequenceProbabilityTableOnClick = (value: number[]) => {
        setValue("consequence", value[0])
        setValue("probability", value[1])
    }

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate >
            <Grid stackable>
                <GridRow>
                    <GridColumn>
                        <ControlDateField
                            name={`latestUpdate`}
                            label={t('latestUpdate')}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <ControlTextArea label={t('comment')} name={`comment`} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <Message info>
                            {t("pickConsequenceProbability")}
                        </Message>
                    </GridColumn>
                </GridRow>


                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput
                            control={control}
                            disabled={false}
                            label={t('consequence')}
                            name={`consequence`}
                            type='number'
                        />
                    </GridColumn>
                    <GridColumn>
                        <ControlInput
                            control={control}
                            disabled={false}
                            label={t('probability')}
                            name={`probability`}
                            type='number'
                        />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn>
                        <ConsequenceProbabilityTable onChange={handleConsequenceProbabilityTableOnClick} value={[watch("consequence"), watch("probability")]} />
                    </GridColumn>
                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default RiskAssessmentForm;