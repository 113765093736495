import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import RiskForm from '../../../forms/RiskForm'
import { IRiskTypeDto } from '../../../../api/risks'

interface IRiskFormPageProps {
    riskTypes: IRiskTypeDto[]
    refresh: () => void
    isNew: boolean
}
const RiskFormPage = ({ riskTypes, refresh, isNew }: IRiskFormPageProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { riskId } = useParams<{ riskId: string }>()
    const {
        isLoading: isLoadingRisk,
        data: selectedRisk,
    } = useGetQueryByParams(!!riskId, API.risks.getById, "selectedRisk", riskId)

    const {
        isLoading: isLoadingDocuments,
        data: documents,
    } = useGetQueryByParams(true, API.documents.getAll, "documents")

    if (isLoadingRisk || isLoadingDocuments || !documents) {
        return <Loading />
    }
    return (
        <Container>
            <Header dividing>{isNew ? t('addValue', { value: t('risks').toLowerCase() }) : t('editValue', { value: t('risks').toLowerCase() })}</Header>
            <RiskForm riskTypes={riskTypes} documents={documents} refresh={refresh} isNew={isNew} selectedRisk={selectedRisk} onCancel={() => navigate(-1)} />
        </Container>
    )
}

export default RiskFormPage
