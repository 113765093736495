import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../state';
import { handleAxiosError } from '../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import FormActions from '../../FormActions';
import { IRiskDto, IRiskTypeDto } from '../../../../api/risks';
import ControlDropdown from '../../../fields/ControlDropdown';
import ControlTextArea from '../../../fields/ControlTextArea';
import * as API from '../../../../api'
import { IDocumentIndex } from '../../../../api/documents';
import RiskAssessmentList from './RiskAssessment/RiskAssessmentList';

export interface IRiskInformationTabProps {
    riskTypes: IRiskTypeDto[]
    documents: IDocumentIndex
    onCancel: () => void
    refresh: () => void
    risk?: IRiskDto
}

export const riskRequestSchema = z.object({
    riskType: z.coerce.number().min(1, { message: "required" }),
    documentId: z.number().optional(),
    risk: z.string().min(1, "required"),
    apt: z.string().optional(),
    impact: z.string().optional(),
    measurment: z.string().optional(),
});

const RiskInformationTab = ({ riskTypes, documents, onCancel, refresh, risk }: IRiskInformationTabProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()

    const { control, handleSubmit } = useForm<IRiskDto>({
        defaultValues: risk ? risk : { apt: "" },
        resolver: zodResolver(riskRequestSchema),
    });

    const onSkillRequest = async (data: IRiskDto) => {
        if (risk) {
            return await API.risks.editRisk(risk.id, data)
        } else {
            return await API.risks.createRisk(data)
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onSkillRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    return (
        <>
            <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
                <FormActions disabled={isSaving} onCancel={onCancel} />
                <Grid stackable>
                    <GridRow columns="2">
                        <GridColumn >
                            <ControlDropdown
                                name="riskType"
                                label={t("riskType")}
                                control={control}
                                disabled={isSaving}
                                required
                                collection={riskTypes.map(d => ({ key: d.id, text: d.name, value: d.id }))} />
                        </GridColumn>
                        <GridColumn >
                            <ControlDropdown
                                name="documentId"
                                label={t("process")}
                                control={control}
                                disabled={isSaving}
                                collection={documents.companyDocuments.map(d => ({ key: d.id, text: d.name, value: d.id }))} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="2">
                        <GridColumn >
                            <ControlTextArea label={t('apt')} name="apt" control={control} disabled={isSaving} />
                        </GridColumn>
                        <GridColumn >
                            <ControlTextArea label={t('risk')} required name="risk" control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>
                    <GridRow columns="2">
                        <GridColumn >
                            <ControlTextArea label={t('impact')} name="impact" control={control} disabled={isSaving} />
                        </GridColumn>
                        <GridColumn >
                            <ControlTextArea label={t('measurment')} name="measurment" control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </Form>
            {
                risk && <GridRow >
                    <GridColumn >
                        <RiskAssessmentList riskId={risk.id} />
                    </GridColumn>
                </GridRow>}
        </>
    );
};

export default RiskInformationTab;