import React from "react";
import { Form, Grid, GridColumn, GridRow } from "semantic-ui-react";
import ControlInput from "../../fields/ControlInput";
import FormActions from "../FormActions";
import { useTranslation } from "react-i18next";
import { getState } from "../../../state";
import { IUserAccountDto } from "../../../api/admin";
import { z } from "zod";
import * as API from "../../../api";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { handleAxiosError } from "../../../actions/error";
import { useMutation } from "react-query";
import ControlRadioGroup from "../../fields/ControlRadioGroup";
import { UserLanguageType } from "../../../api/types/Enum";

interface UserAccountFormProps {
    selectedUserAccount: IUserAccountDto | null;
    refresh: () => void;
    onClose: () => void;
}

const UserAccountListSchema = z.object({
    name: z.string().optional(),
    username: z.string().optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    language: z.nativeEnum(UserLanguageType)
});

const UserAccountForm : React.FC<UserAccountFormProps> = ({ selectedUserAccount, refresh, onClose }) => {

    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()

    const { control, handleSubmit } = useForm<IUserAccountDto>({
        resolver: zodResolver(UserAccountListSchema),
        defaultValues: selectedUserAccount ? selectedUserAccount: { name: "", username: "", email: "", phone: "" }
    });

    const onUserAccountRequest = async (data: IUserAccountDto) => {
        if (selectedUserAccount?.id) {
            return await API.admin.adminUpdateUserAccount(selectedUserAccount.id, data);
        } else {
            return await API.admin.createUserAccount(data);
        }
    };

    const { isLoading: isSaving, mutate } = useMutation(onUserAccountRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch) (error);
        },
        onSuccess: () => {
            toast.success(t('saved'));
            refresh();
            onClose();
        },
    });

    return (

        <Form onSubmit={handleSubmit((data) => mutate(data))} noValidate>
            <Grid stackable>
            
                <GridRow columns="1">
                    <GridColumn>
                        <ControlInput name="name" label={t('name')} placeholder={t('name')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <ControlInput name="username" label={t('userName')} placeholder={t('userName')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <ControlInput name="email" label={t('email')} placeholder={t('email')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <ControlInput name="phone" label={t('phoneNumber')} placeholder={t('phoneNumber')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                <GridColumn >
                        <ControlRadioGroup control={control} name='language' disabled={isSaving} label={t('language')}
                            options={[
                                { value: UserLanguageType.Swedish, text: t("swe") },
                                { value: UserLanguageType.English, text: t("eng") },
                            ]} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <FormActions onCancel={onClose} disabled={isSaving}/>
                    </GridColumn>
                </GridRow>

            </Grid>
        </Form>
    )
}

export default UserAccountForm;