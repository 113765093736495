import React, { useMemo, useState } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../api'
import Loading from '../../../../common/Loading'
import { ColumnFiltersState, createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { Bell, Edit, Trash2 } from 'react-feather'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import TableReact from '../../../../wrappers/TableReact'
import { getState } from '../../../../../state'
import ModalWrapper from '../../../../wrappers/ModalWrapper'
import RiskActionPlanForm from '../RiskActionPlanForm'
import UserNameDisplay from '../../../../common/UserNameDisplay'
import { getUserFromState } from '../../../../../state/helpers'
import { formatDate } from '../../../../../actions/util'
import DateCell from '../../../../common/cells/DateCell'
import { EnumMultiFilterSelect, enumMultiFilter } from '../../../../wrappers/TableReact/Filters/EnumMultiFilter'
import { IssueStatus } from '../../../../../api/types/Enum'
import { useMutation } from 'react-query'
import { handleAxiosError } from '../../../../../actions/error'
import { toast } from 'react-toastify'
import useTableStorageBackedState from '../../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { issueStatusRowClass } from '../../../../wrappers/TableReact/RowClassHelpers'
import { useDeleteRiskActionPlan } from './useDeleteRiskActionPlan'
import { IRiskActionPlanDto } from '../../../../../api/risks'

interface IRiskActionPlanListProps {
    riskId: number
}
const RiskActionPlanList = ({ riskId }: IRiskActionPlanListProps) => {
    const { t } = useTranslation()
    const { state, dispatch } = getState()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, API.risks.getActionPlans, "riskActionPlanList", riskId)
    const [showAll, setShowAll] = useTableStorageBackedState('riskActionPlanListShowAllState', false);
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('riskActionPlanListFilteringState', []);
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('riskActionPlanListSortingState', [{ id: "status", desc: false }, { id: "doneBefore", desc: false }]);

    const [showAddModal, setShowAddModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedActionPlan, setSelectedActionPlan] = useState<IRiskActionPlanDto | null>(null);
    const { isDestroying, onDestroy } = useDeleteRiskActionPlan(refresh)
    const onCancel = () => {
        setOpen(false)
        setSelectedActionPlan(null)
    }

    const handleSetSelecteKeyFigure = (keyFigure: IRiskActionPlanDto) => {
        setOpen(true)
        setSelectedActionPlan(keyFigure)
    }

    const onActionPlanRemind = async (data: { riskId: number, actionPlanId: number }) => await API.suppliers.remindActionPlan(data.riskId, data.actionPlanId)
    const { isLoading: isSending, mutate: onNotify } = useMutation(onActionPlanRemind, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast(t('mailHasBeenSend'), {
                    type: 'success',
                })
            }
        },
    })
    const columnHelper = createColumnHelper<IRiskActionPlanDto>()
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                size: 95,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' disabled={isDestroying} onClick={() => handleSetSelecteKeyFigure(row.original)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        <Popup
                            content={!getUserFromState(state)(row.original.responsibleId)?.active ? t('disabledResponsibleReminder') : t('sendReminder')}
                            trigger={
                                <span>
                                    <Button type='button'
                                        onClick={() => onNotify({ riskId: riskId, actionPlanId: row.original.id })}
                                        icon={<SemanticFeatherIcon FeatherIcon={Bell} />}
                                        disabled={!getUserFromState(state)(row.original.responsibleId)?.active}
                                        loading={isSending} />
                                </span>
                            }
                        />
                        <Button title={t('delete')} disabled={isDestroying} onClick={() => onDestroy({
                            confirmText: t('confirm.deleteValue', { value: row.original.topic }),
                            id: row.original.id
                        })}
                            type='button'
                            color='red'
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />

                    </>
            }),
            columnHelper.accessor((r) => t(`issueStatus.${r.status}`), {
                id: "status",
                header: t('status') as any,
                filterFn: enumMultiFilter,
                size: 80,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | IssueStatus[]) => void, value: IssueStatus[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={IssueStatus}
                            enumLangKey="issueStatus"
                        />
                    ),
                },
                cell: ({ row }) => <span>{t(`issueStatus.${row.original.status}`)}</span>
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.responsibleId)?.name ?? "", {
                id: "responsibleId",
                header: t('responsibleId') as any,
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.responsibleId ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),
            columnHelper.accessor("topic", {
                id: "topic",
                header: t('topic') as any,
            }),
            columnHelper.accessor(r => r.doneBefore ? formatDate(r.doneBefore) : "", {
                id: "doneBefore",
                header: t('doneBefore') as any,
                cell: ({ row }) => <DateCell date={row.original.doneBefore} />
            }),

        ], [columnHelper, t, state, isDestroying, onDestroy, isSending, onNotify, riskId])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: columns,
        enableGlobalFilter: false,
        enableGrouping: false,
        state: {
            columnFilters,
            sorting,
        },
        initialState: {
            columnFilters: [{ id: "status", value: [IssueStatus.Active, IssueStatus.Evaluate] }],
            sorting: [{ id: "status", desc: false }, { id: "doneBefore", desc: false }],
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <FullHeightStackingContainer>
            <TableReact table={table}
                onAddCallback={() => setShowAddModal(true)}
                overflowable
                canExport={false}
                rowClassRenderer={(r) => issueStatusRowClass(r)}
                showAllFilter={{ showAll, setShowAll, checked: [{ id: "status", value: [] }], notChecked: [{ id: "status", value: [IssueStatus.Active, IssueStatus.Evaluate] }] }} />

            <ModalWrapper title={t('addValue', { value: t('actionPlan').toLowerCase() })} open={showAddModal} onClose={() => setShowAddModal(false)} size='small'>
                <RiskActionPlanForm onCancel={() => setShowAddModal(false)} refresh={refresh} isNew riskId={riskId} />
            </ModalWrapper>

            {selectedActionPlan && <ModalWrapper title={t('editValue', { value: t('actionPlan').toLowerCase() })} open={open} onClose={onCancel} size='small'>
                <RiskActionPlanForm onCancel={onCancel} refresh={refresh} isNew={false} riskId={riskId} selectedActionPlan={selectedActionPlan} />
            </ModalWrapper>}

        </FullHeightStackingContainer>
    )
}

export default RiskActionPlanList
