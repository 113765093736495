import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SurveyPage from '../surveys/surveyPage'
import App from './app'
import AppLoader from './appLoader'
import AdminModule from '../admin'
import SupplierDeviationFormPage from '../modules/external/SupplierDeviationFormPage'
import UserClothesSize from '../modules/userInfo/UserClothesSize'
import UserIce from '../modules/userInfo/UserIce'
import ChangePassword from '../modules/userInfo/ChangePassword'
import ClothesSizeList from '../modules/admin/ClothesList'
import IceList from '../modules/admin/IceList'
import DepartmentList from '../modules/admin/DepartmentList'
import LocationList from '../modules/admin/LocationList'
import UserAccountList from '../modules/admin/UserAccountList'

const Routing: React.FunctionComponent = () => (
    <Routes>
        <Route
            path="/surveys/do/:surveyGuid"
            element={<SurveyPage />}
        />
        <Route
            path="/external/supplier/:sId/deviations/:deviationId"
            element={<SupplierDeviationFormPage />}
        />
        <Route
            path="/admin/*"
            element={
                <AppLoader>
                    <AdminModule />
                </AppLoader>
            }
        />
        <Route
            path="/userinfo"
            element={
                <AppLoader>
                    <UserClothesSize />
                </AppLoader>
            }
        />
        <Route
            path="/userice"
            element={
                <AppLoader>
                    <UserIce />
                </AppLoader>
            }
        />
        <Route
            path="/userauth"
            element={
                <AppLoader>
                    <ChangePassword />
                </AppLoader>
            }
        />
        <Route
            path="/userform"
            element={
                <AppLoader>
                    <UserClothesSize />
                </AppLoader>
            }
        />
        <Route
            path="/clotheslist"
            element={
                <AppLoader>
                    <ClothesSizeList />
                </AppLoader>
            }

        />
        <Route 
            path="/icelist"
            element={
                <AppLoader>
                    <IceList />
                </AppLoader>
            }
        />
        
        <Route
            path='/departmentlist'
            element={
                <AppLoader>
                    <DepartmentList />
                </AppLoader>
            } 
        />

        <Route 
            path='/location'
            element={
                <AppLoader>
                    <LocationList/>
                </AppLoader>
            }
        />

        <Route 
            path='/useraccountlist'
            element={
                <AppLoader>
                    <UserAccountList/>
                </AppLoader>
            }
        />

        <Route
            path="*"
            element={
                <AppLoader>
                    <App />
                </AppLoader>
            }
        />

    </Routes>
)

export default Routing