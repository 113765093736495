import { FilterFn } from '@tanstack/react-table';
import React from 'react';
import styled from 'styled-components';
import FlexBox from '../../../../common/flexBox';

const FilterInput = styled.input`
  width: 100%;
  height: 2.4375em;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
  padding: 16.5px 14px;
  border-radius: 4px;
  border-style: inset;
  border: 1px solid hsl(210deg 3% 68% / 90%);
`;

// Filter function to handle numbers between a given range
export const riskRangeFilterFn: FilterFn<any> = (row, columnId, value) => {
    const number = row?.getValue(columnId) as number;
    const [start, end] = value;
    if ((start || end) && number == null) return false;
    if (start != null && end == null) {
        return number >= start;
    } else if (start == null && end != null) {
        return number <= end;
    } else if (start != null && end != null) {
        return number <= start || number >= end;
    } else {
        return true;
    }
};

interface IRiskRangeFilterProps {
    setFilterValue: (updater: any) => void;
    value: number[] | null;
}

export function RiskRangeFilter({ setFilterValue, value }: IRiskRangeFilterProps) {
    const handleRangeChange = (inputValue: string, isStart: boolean) => {
        const numericValue = inputValue === '' ? null : parseFloat(inputValue);
        const newValue = value ? [...value] : [];
        if (isStart) {
            newValue[0] = numericValue || 0;
        } else {
            newValue[1] = numericValue || 0;
        }

        // Update the filter value
        setFilterValue(newValue);
    };

    return (
        <FlexBox direction="column" gap="4px">
            <FilterInput
                type="number"
                value={value && value[0] != null ? value[0] : ''} // Display an empty string if value is null
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRangeChange(e.target.value, true)}
                placeholder="Min value"
            />
            <FilterInput
                type="number"
                value={value && value[1] != null ? value[1] : ''} // Display an empty string if value is null
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRangeChange(e.target.value, false)}
                placeholder="Max value"
            />
        </FlexBox>
    );
}
