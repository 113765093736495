import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ModuleMenu from '../../../common/moduleMenu'

const RiskMenu: React.FunctionComponent = () => {
    const { t } = useTranslation()

    /* eslint react/jsx-key: "off" */
    return ModuleMenu({
        all: [<NavLink to={`./`}>{t('risks')}</NavLink>, <NavLink to={`./emergency`}>{t('emergency')}</NavLink>],
        admin: [<NavLink to={`./admin/groups`}>Risk/möjlighetstyp</NavLink>],

    })
}

export default RiskMenu
