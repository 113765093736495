import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header, Button, Container } from 'semantic-ui-react';
import TableReact from "../../../wrappers/TableReact";
import {
    createColumnHelper, useReactTable, ColumnDef, getCoreRowModel,
    getPaginationRowModel, getFilteredRowModel, getSortedRowModel,
    getFacetedRowModel, getFacetedUniqueValues, getGroupedRowModel,
    getExpandedRowModel,
} from "@tanstack/react-table";
import SemanticFeatherIcon from "../../../icons/SemanticFeatherIcon";
import { Edit } from "react-feather";
import ModalWrapper from "../../../wrappers/ModalWrapper";
import { useGetQueryByParams } from "../../../../hooks/useGetQueryByParams";
import * as API from '../../../../api';
import Loading from "../../../common/Loading";
import ClothesSizeForm from "../../../forms/ClothesSizeForm";
import { IClothesSizeDto } from "../../../../api/admin";

const ClothesList = () => {

    const { t } = useTranslation()

    const {
        isLoading,
        data: clothesSizeList,
        refresh,
    } = useGetQueryByParams(true, API.admin.getClothesSizeList, "clothesSizeList");

    const [selectedUser, setSelectedUser] = useState<IClothesSizeDto | null>(null);

    const [isModalOpen, setModalOpen] = useState(false);

    const handleEditClick = (user: IClothesSizeDto) => {
        setSelectedUser(user);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedUser(null);
        setModalOpen(false);
    };

    const columnHelper = createColumnHelper<IClothesSizeDto>();

    const columns = useMemo<ColumnDef<IClothesSizeDto>[]>(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 50,
                minSize: 50,
                maxSize: 50,
                cell: ({ row }) => (
                    <Button
                        title={t("edit")}
                        type="button"
                        onClick={() => handleEditClick(row.original)}
                        icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                    />
                ),
            }),
            {
                accessorKey: "name",
                header: t("name"),
            },
            {
                accessorKey: "pantSize",
                header: t("pantSize"),
            },
            {
                accessorKey: "shirtSize",
                header: t("shirtSize"),
            },
            {
                accessorKey: "shoeSize",
                header: t("shoeSize"),
            },
            {
                accessorKey: "gloveSize",
                header: t("gloveSize"),
            },
        ],
        [t, columnHelper]
    );

    const table = useReactTable({
        data: clothesSizeList || [],
        columns,
        getGroupedRowModel: getGroupedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    if (isLoading) {
        return <Loading />
    }

    return (
        <Container>
            <Header dividing content={t('clothesSizeFormHeader')} />
            <TableReact table={table} canExport={true} />
            <ModalWrapper title={t('editClothesSize')} open={isModalOpen} onClose={closeModal}>
                <ClothesSizeForm selectedUser={selectedUser} refresh={refresh} onClose={closeModal}
                />
            </ModalWrapper>
        </Container>
    );
};

export default ClothesList;