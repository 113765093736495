import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container, Header } from "semantic-ui-react";
import { useGetQueryByParams } from "../../../../hooks/useGetQueryByParams";
import * as API from "../../../../api";
import TableReact from "../../../wrappers/TableReact";
import ModalWrapper from "../../../wrappers/ModalWrapper";
import UserAccountForm from "../../../forms/UserAccountForm";
import { IUserAccountDto } from "../../../../api/admin";
import { ColumnDef, createColumnHelper, getCoreRowModel, getFacetedRowModel, 
    getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, 
    getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { ButtonContainer } from "../../../surveys/surveyPage/surveyBase";
import SemanticFeatherIcon from "../../../icons/SemanticFeatherIcon";
import { Edit, Trash2, Lock } from "react-feather";
import Loading from "../../../common/Loading";
import { useDestroyUserAccount } from "./useDestroyUserAccount";

const UserAccountList = () => {

    const { t } = useTranslation()

    const {
        isLoading,
        data: userAccountList,
        refresh,
    } = useGetQueryByParams(true, API.admin.getMyUserAccounts, "userAccountList");
    console.log("ISLOADING:", isLoading);
    console.log("USERACCOUNTLIST:", userAccountList);
    console.log("TYPE OF USERACCOUNTLIST:", typeof userAccountList);

    const [ selectedUserAccount, setSelectedUserAccount ] = useState<IUserAccountDto | null>(null);
    const [ isEditModalOpen, setEditModalOpen ] = useState(false);
    const [ isAddModalOpen, setAddModalOpen ] = useState(false);
    const { isDestroying, onDestroy } = useDestroyUserAccount(refresh)

    const handleEditClick = (userAccount: IUserAccountDto) => {
        setSelectedUserAccount(userAccount);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedUserAccount(null);
        setEditModalOpen(false);
    };

    const handleAddClick = () => {
        setSelectedUserAccount(null);
        setAddModalOpen(true);
    };

    const closeAddModal = () => {
        setAddModalOpen(false);
    };

    const columnHelper = createColumnHelper<IUserAccountDto>();

    const columns = useMemo<ColumnDef<IUserAccountDto>[]>(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 115,
                cell: ({ row }) => ( 
                    <ButtonContainer>
                        <Button
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                            negative
                            title={t('delete')}
                            onClick={() => onDestroy({ confirmText: t('confirm.deleteValue', { value: row.original.name }), id: row.original.id })}
                        />
                        <Button
                            title={t("edit")}
                            type="button"
                            onClick={() => handleEditClick(row.original)}
                            icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                        />
                        <Button
                        title={t("lock")}
                        type="button"
                        onClick={() => (row.original)}
                        icon={<SemanticFeatherIcon FeatherIcon={Lock} />}
                        />
                    </ButtonContainer>
                ),
            }),
            {
                accessorKey: "name",
                header: t("name"),
            },
            {
                accessorKey: "username",
                header: t("userName"),
            },
            {
                accessorKey: "email",
                header: t("email"),
            },
            {
                accessorKey: "phone",
                header: t("phoneNumber"),
            },
            {
                accessorKey: "language",
                header: t("language"),
                size: 80,
            },
            {
                accessorKey: "latestTokenIssued",
                header: t("latestTokenIssued"),
            },
        ],
        [t, columnHelper, isDestroying, onDestroy]
    );

    const table = useReactTable({
        data: userAccountList || [],
        columns,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    if (isLoading) {
        return <Loading />
    }

    return (
        <Container>
            <Header dividing content={t('userAccountListHeader')} />
            <TableReact table={table} canExport={true} onAddCallback={handleAddClick} />

            <ModalWrapper title={t('editUser')} open={isEditModalOpen} onClose={closeEditModal} >
                <UserAccountForm selectedUserAccount={selectedUserAccount} refresh={refresh} onClose={closeEditModal} />
            </ModalWrapper>

            <ModalWrapper title={t('addUser')} open={isAddModalOpen} onClose={closeAddModal} >
                <UserAccountForm selectedUserAccount={null} refresh={refresh} onClose={closeAddModal} />
            </ModalWrapper>

        </Container>
    );
}

export default UserAccountList;