import { Profile } from 'oidc-client'

export enum AdminRoles {
    CompetenceAdmin = "komA",
    DocumentsAdminRole = 'dokA',
    IssuesAdminRole = 'avvA',
    ImprovementsAdmin = "forA",
    GoalsAdminRole = 'malA',
    ProjectsAdminRole = 'proA',
    RisksAdminRole = 'aspA',
    SurveysAdminRole = 'kunA',
    SystemAdminRole = 'SA',
    ViqmaAdminRole = 'Viqma',
    SupplierAdminRole = 'levA',
}

export interface ProfileWithRoles extends Profile {
    role: string[]
    companyId: string
}
// TODO: change roleName to enum to prevent magic strings
export const isInRole = (profile: ProfileWithRoles | undefined, roleName: string) =>
    (profile?.role?.indexOf(roleName) ?? -1) !== -1
