import * as API from "../../../../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { handleAxiosError } from "../../../../../../actions/error"
import { getState } from "../../../../../../state"

interface IDestroyRiskAssessment {
    riskId: number
    assessmentId: number
    confirmText: string
}

export function useDeleteRiskAssesment(refresh: () => void) {
    const { t } = useTranslation()
    const { dispatch } = getState()

    const deleteRiskAsessment = async (data: IDestroyRiskAssessment) => {
        if (window.confirm(data.confirmText)) {
            return await API.risks.deleteRiskAsessment(data.riskId, data.assessmentId)
        }
    }

    const { isLoading: isDestroying, mutate: onDestroy } = useMutation(deleteRiskAsessment, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast.success(t('saved'))
                refresh()
            }
        },
    })

    return {
        isDestroying,
        onDestroy,
    }
}
