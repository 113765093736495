import fileDownload from 'js-file-download'
import { Action } from '../../../../reducer'
import { getAuthedAxios } from '../../../../auth'
import { handleAxiosError } from '../../../../actions/error'
import { Column, Row } from '@tanstack/react-table'

export enum ExportReportType {
    excel = 0,
    pdf = 1
}

interface RowValue {
    values: any[];
    subRows: RowValue[];
}

const processSubRows = (
    subRows: Row<any>[],
    columns: Column<any, unknown>[]
): RowValue[] => {
    return subRows.map(subRow => {
        const values = columns.map(column => {
            const value = subRow.getValue(column.id) ?? subRow.original[column.id] ?? "";
            return value;
        });
        return {
            values,
            subRows: processSubRows(subRow.subRows || [], columns),
        };
    });
};

const generateReportRows = (rows: Row<any>[], columns: Column<any, unknown>[]) => {
    const headers = columns.map(column => column.columnDef.header || column.id);
    const isGrouped = columns.some(column => column.getIsGrouped());
    if (isGrouped) {
        const rowValues = rows.map(row => {
            const values = Array(columns.length - 1).fill("");
            values[0] = row.groupingValue;

            const subRows = processSubRows(row.subRows || [], columns);

            return { values, subRows };
        });

        return { headers, rowValues };
    }
    const rowValues: RowValue[] = rows.map(row => {
        const values = columns.map(column => {
            const value = row.getValue(column.id) ?? row.original[column.id] ?? "";
            return value || "";
        });

        const subRows = processSubRows(row.subRows || [], columns);

        return { values, subRows };
    });

    return { headers, rowValues };
};

export const generateReport =
    (dispatch: React.Dispatch<Action>) =>
        (
            reportType: ExportReportType,
            fileName: string,
            rows: Row<any>[],
            trackedColumns: Column<any, unknown>[],
        ) => {
            const reportRows = generateReportRows(rows, trackedColumns)

            return getAuthedAxios().then(axios =>
                axios
                    .post(
                        `${window.env.REACT_APP_REPORT_SERVICE}/api/Report`,
                        {
                            name: fileName,
                            type: reportType,
                            columns: reportRows.headers,
                            rows: reportRows.rowValues,
                        },
                        {
                            responseType: 'blob',
                        },
                    )
                    .then(response => {
                        const contentDisposition = response.headers['content-disposition'] as string
                        const fileName = contentDisposition
                            .split(';')
                            .filter(x => x.indexOf('filename') !== -1)[0]
                            .split('=')[1]

                        fileDownload(response.data, fileName)
                    })
                    .catch(handleAxiosError(dispatch)),
            )
        }
