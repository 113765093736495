import React from 'react'
import { Container } from 'semantic-ui-react'
import Loading from '../../../common/Loading'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams';
import * as API from '../../../../api'
import IceForm from '../../../forms/IceForm';

const UserIce = () => {

    const {
        isLoading,
        data,
        refresh,
    } = useGetQueryByParams(true, API.admin.getMyIce, "userIce")

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <Container>
            <IceForm refresh={refresh} userIce={data}/>
        </Container>
    );
};

export default UserIce;