import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

const handleBackgroundColors = (rowIndex: number, colIndex: number, value: number[], cell: string | number[]) => {
    // eslint-disable-next-line eqeqeq
    if (Array.isArray(cell) && Array.isArray(value) && cell[0] == value[0] && cell[1] == value[1]) {
        return "black; color: white;";
    }
    if (colIndex) {
        if (rowIndex === 7 && colIndex === 5 || rowIndex === 8 && colIndex >= 4 || rowIndex === 9 && colIndex >= 3) return "red; color: white;";
        if (rowIndex === 6 && colIndex === 5 || rowIndex === 7 && colIndex === 4 || rowIndex === 8 && colIndex === 3 || rowIndex === 9 && colIndex === 2) return "orange; color: black;";
        if (rowIndex === 5 && colIndex === 5 || rowIndex === 6 && (colIndex === 4 || colIndex === 3) || rowIndex === 7 && (colIndex === 3 || colIndex === 2) || rowIndex === 8 && colIndex === 2 || rowIndex === 9 && colIndex === 1) return "yellow; color: black;";
        if (rowIndex === 5 && (colIndex === 4 || colIndex === 3) || rowIndex === 6 && colIndex === 2 || rowIndex === 7 && colIndex === 1 || rowIndex === 8 && colIndex === 1) return "lightgreen; color: black;";
        if (rowIndex === 5 && (colIndex === 1 || colIndex === 2) || rowIndex === 6 && colIndex === 1) return "green; color: white;";
        return "blue; color: white;";
    }
    return "white; color: black;";
}

const CustomColoredTableCell = styled(Table.Cell) <{ rowIndex: number, colIndex: number, value: number[], cell: string | number[] }>`
    background-color: ${p => handleBackgroundColors(p.rowIndex, p.colIndex, p.value, p.cell)}
    cursor: ${p => p.colIndex ? "pointer" : "default"};
`;

interface IConsequenceProbabilityTableProps {
    onChange: (value: number[]) => void
    value: number[]
}
const ConsequenceProbabilityTable = ({ onChange, value }: IConsequenceProbabilityTableProps) => {
    const { t } = useTranslation();
    const rows = [
        [t('consequence1'), [1, 1], [1, 2], [1, 3], [1, 4], [1, 5]],
        [t('consequence2'), [2, 1], [2, 2], [2, 3], [2, 4], [2, 5]],
        [t('consequence3'), [3, 1], [3, 2], [3, 3], [3, 4], [3, 5]],
        [t('consequence4'), [4, 1], [4, 2], [4, 3], [4, 4], [4, 5]],
        [t('consequence5'), [5, 1], [5, 2], [5, 3], [5, 4], [5, 5]],
        [t('consequence-1'), [-1, 1], [-1, 2], [-1, 3], [-1, 4], [-1, 5]],
        [t('consequence-2'), [-2, 1], [-2, 2], [-2, 3], [-2, 4], [-2, 5]],
        [t('consequence-3'), [-3, 1], [-3, 2], [-3, 3], [-3, 4], [-3, 5]],
        [t('consequence-4'), [-4, 1], [-4, 2], [-4, 3], [-4, 4], [-4, 5]],
        [t('consequence-5'), [-5, 1], [-5, 2], [-5, 3], [-5, 4], [-5, 5]],
    ];
    return (
        <Table celled striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t('consequenceProbability')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('probability1')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('probability2')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('probability3')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('probability4')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('probability5')}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {rows.map((row, rowIndex) => (
                    <Table.Row key={rowIndex}>
                        {row.map((cell, colIndex) => (
                            <CustomColoredTableCell
                                rowIndex={rowIndex}
                                colIndex={colIndex}
                                value={value}
                                cell={cell}
                                key={`${rowIndex}-${colIndex}`}
                                onClick={colIndex && Array.isArray(cell) ? () => onChange(cell) : null}
                            >
                                {Array.isArray(cell) ? cell.join(", ") : cell}
                            </CustomColoredTableCell>
                        ))}
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

export default ConsequenceProbabilityTable;
