import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FullHeightStackingContainer from '../../../common/fullHeightStackingContainer'
import { Button, Checkbox, Header, Message, Popup } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import {
    GroupingState,
    createColumnHelper,
    ColumnDef,
    useReactTable,
    getExpandedRowModel,
    getGroupedRowModel,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    VisibilityState,
    PaginationState,
    ColumnFiltersState,
    SortingState
} from '@tanstack/react-table'
import DateCell from '../../../common/cells/DateCell'
import TableReact from '../../../wrappers/TableReact'
import { formatDate } from '../../../../actions/util'
import useTableStorageBackedState from '../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Edit, Info, Send } from 'react-feather'
import { globalFilterFn } from '../../../wrappers/TableReact/Filters/GlobalFilter'
import { BooleanFilter, booleanFilter } from '../../../wrappers/TableReact/Filters/BooleanFilter'
import { ISupplierDto, ISupplierQuestionDto } from '../../../../api/suppliers'
import { useSendSupplierLink } from './useSendSupplierLink'
import * as z from 'zod';
import { useIsInRole } from '../../../../hooks/role'
import { AdminRoles } from '../../../../auth/roles'
import { addDays, addYears, isBefore, isPast } from 'date-fns'
import { hasSupplierConcernModule } from '../../../../auth/modules'

const emailSchema = z.string().email();
interface ISuppliersListProps {
    suppliers: ISupplierDto[]
    externalQuestions: ISupplierQuestionDto[]
    refresh: () => void
}
const defaultHiddenColumns: { [x: string]: boolean; } = {
    quality: false,
    environment: false,
    workEnvironment: false,
    csr: false,
    loadBearingStructureWeldingCertificate: false,
    qualityCertificationExpires: false,
    environmentCertificationExpires: false,
    workEnvironmentCertificationExpires: false,
    csrCertificationExpires: false,
    otherCertificationExpires: false,
}

const SuppliersList = ({ suppliers, externalQuestions, refresh }: ISuppliersListProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { isSending, onNotify } = useSendSupplierLink(refresh)
    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('supplierVisibilityState', defaultHiddenColumns);
    const [grouping, setGrouping] = useTableStorageBackedState<GroupingState>('supplierGroupingVisibility', []);
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('supplierPaginationState', { pageSize: 10, pageIndex: 0 });
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('supplierFilteringState', [{ id: "active", value: true }]);
    const [globalFilter, setGlobalFilter] = useTableStorageBackedState('supplierGlobalFilterState', "");
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('supplierSortingState', [{ id: "group", desc: false }]);
    const [showAll, setShowAll] = useTableStorageBackedState('supplierListShowAllState', false);
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.SupplierAdminRole)
    const hasSupplierConcern = hasSupplierConcernModule()

    const columnHelper = createColumnHelper<ISupplierDto>()

    const supplierColumns: ColumnDef<ISupplierDto, any>[] = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 95,
                minSize: 95,
                cell: ({ row }) => {
                    const isEmailInvalid = emailSchema.safeParse(row.original.email).success;
                    return (
                        <>
                            <Button title={t('edit')} type='button' onClick={() => navigate(`./detail/${row.original.id}`)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                            {!hasSupplierConcern && <Popup
                                content={isEmailInvalid ? t('sendSupplierLink') : t('disabledSendSupplierLink')}
                                trigger={
                                    <span>
                                        <Button type='button'
                                            onClick={() => onNotify({ id: row.original.id, confirmText: t('confirm.supplierLink') })}
                                            icon={<SemanticFeatherIcon FeatherIcon={Send} />}
                                            disabled={!isEmailInvalid}
                                            loading={isSending} />
                                    </span>
                                }
                            />}
                        </>
                    )
                }

            }),
            columnHelper.accessor("active", {
                id: "active",
                header: t('active'),
                size: 80,

                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.active} />
            }),
            columnHelper.accessor("group", {
                id: "group",
                size: 80,
                header: t('group') as any,
            }),
            columnHelper.accessor("name", {
                id: "name",
                header: t('name') as any,
            }),
            columnHelper.accessor(r => r.service ? r.service : "", {
                id: "service",
                header: t('service') as any,
            }),
            columnHelper.accessor(r => r.organizationNumber ? r.organizationNumber : "", {
                id: "organizationNumber",
                header: t('organizationNumber') as any,
            }),
            columnHelper.accessor("language", {
                id: "language",
                size: 80,
                header: t('language') as any,
            }),
            columnHelper.accessor("quality", {
                id: "quality",
                header: t('quality'),
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.quality} />
            }),
            columnHelper.accessor("environment", {
                id: "environment",
                header: t('environment'),
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.environment} />
            }),
            columnHelper.accessor("workEnvironment", {
                id: "workEnvironment",
                header: t('workEnvironment'),
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.workEnvironment} />
            }),
            columnHelper.accessor("csr", {
                id: "csr",
                header: t('csr'),
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.csr} />
            }),
            columnHelper.accessor("loadBearingStructureWeldingCertificate", {
                id: "loadBearingStructureWeldingCertificate",
                header: t('loadBearingStructureWeldingCertificate'),
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.loadBearingStructureWeldingCertificate} />
            }),
            columnHelper.accessor(r => r.qualityCertificationExpires ? formatDate(r.qualityCertificationExpires) : "", {
                id: "qualityCertificationExpires",
                header: t('qualityCertificationExpires') as any,
                cell: ({ row }) => <DateCell
                    error={(row.original.qualityCertificationExpires && isPast(new Date(row.original.qualityCertificationExpires))) ?? false}
                    date={row.original.qualityCertificationExpires} />
            }),
            columnHelper.accessor(r => r.environmentCertificationExpires ? formatDate(r.environmentCertificationExpires) : "", {
                id: "environmentCertificationExpires",
                header: t('environmentCertificationExpires') as any,
                cell: ({ row }) => <DateCell
                    error={(row.original.environmentCertificationExpires && isPast(new Date(row.original.environmentCertificationExpires))) ?? false}
                    date={row.original.environmentCertificationExpires} />
            }),
            columnHelper.accessor(r => r.workEnvironmentCertificationExpires ? formatDate(r.workEnvironmentCertificationExpires) : "", {
                id: "workEnvironmentCertificationExpires",
                header: t('workEnvironmentCertificationExpires') as any,
                cell: ({ row }) => <DateCell
                    error={(row.original.workEnvironmentCertificationExpires && isPast(new Date(row.original.workEnvironmentCertificationExpires))) ?? false}
                    date={row.original.workEnvironmentCertificationExpires} />
            }),
            columnHelper.accessor(r => r.csrCertificationExpires ? formatDate(r.csrCertificationExpires) : "", {
                id: "csrCertificationExpires",
                header: t('csrCertificationExpires') as any,
                cell: ({ row }) => <DateCell
                    error={(row.original.csrCertificationExpires && isPast(new Date(row.original.csrCertificationExpires))) ?? false}
                    date={row.original.csrCertificationExpires} />
            }),
            columnHelper.accessor(r => r.otherCertificationExpires ? formatDate(r.otherCertificationExpires) : "", {
                id: "otherCertificationExpires",
                header: t('otherCertificationExpires') as any,
                cell: ({ row }) => <DateCell
                    error={(row.original.otherCertificationExpires && isPast(new Date(row.original.otherCertificationExpires))) ?? false}
                    date={row.original.otherCertificationExpires} />
            }),
            columnHelper.accessor(r => r.externalAssessmentSentDate ? formatDate(r.externalAssessmentSentDate) : "", {
                id: "externalAssessmentSentDate",
                header: t('externalAssessmentSentDate') as any,
                cell: ({ row }) => <DateCell date={row.original.externalAssessmentSentDate} />
            }),
            columnHelper.accessor(r => r.externalAssessmentAnsweredDate ? formatDate(r.externalAssessmentAnsweredDate) : "", {
                id: "externalAssessmentAnsweredDate",
                header: t('externalAssessmentAnsweredDate') as any,
                cell: ({ row }) => <DateCell
                    error={(row.original.externalAssessmentSentDate && row.original.externalAssessmentAnsweredDate && isBefore(new Date(row.original.externalAssessmentAnsweredDate), new Date(row.original.externalAssessmentSentDate))) ?? false}
                    date={row.original.externalAssessmentAnsweredDate} />
            }),
            columnHelper.accessor("interval", {
                id: "interval",
                size: 80,
                header: t('interval') as any,
            }),
            columnHelper.accessor(r => r.assessedDate ? formatDate(r.assessedDate) : "", {
                id: "assessedDate",
                header: t('assessedDate') as any,
                cell: ({ row }) => <DateCell date={row.original.assessedDate}
                    error={(row.original.interval !== null && row.original.interval !== 0 && row.original.assessedDate && isBefore(addYears(addDays(new Date(row.original.assessedDate), 1), row.original.interval), new Date())) ?? false} />
            }),
            columnHelper.accessor("actionPlan", {
                id: "actionPlan",
                header: t('actionPlan'),
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.actionPlan} />
            }),
            columnHelper.accessor(r => r.email ? r.email : "", {
                id: "email",
                header: t('email') as any,
                cell: ({ row }) => <a href={`mailto:${row.original.email}`}>{row.original.email}</a>
            }),
            columnHelper.accessor("averageScore", {
                id: "averageScore",
                enableColumnFilter: false,
                header: t('averageScore') as any,
            }),

        ], [columnHelper, navigate, t, isSending, onNotify, hasSupplierConcern])

    const table = useReactTable({
        data: suppliers || [],
        columns: supplierColumns,
        columnResizeMode: "onChange",
        state: {
            columnVisibility,
            grouping,
            pagination,
            columnFilters,
            globalFilter,
            sorting
        },
        initialState: {
            globalFilter: "",
            columnFilters: [],
            grouping: [],
            sorting: [{ id: "group", desc: false }],
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: globalFilterFn,
        onGroupingChange: setGrouping,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })
    return (
        <FullHeightStackingContainer>
            <Header dividing>{t('adminSuppliers')}</Header>
            {!externalQuestions.length && !hasSupplierConcern && <Message info
                header={t("externalQuestions")}
                content={<span>
                    {t('noExternalQuestions')}
                    {isAdmin
                        ? <a href="/lev/levFr.aspx">{t('toAddLink')}</a>
                        : t('contactSystemAdmin')}
                </span>}
                icon={<SemanticFeatherIcon FeatherIcon={Info} centerIcon size="32px" />} />}
            <TableReact table={table}
                onAddCallback={hasSupplierConcern ? undefined : () => navigate("./detail/new")}
                canExport
                showAllFilter={{ showAll, setShowAll, checked: [], notChecked: [{ id: "active", value: true }] }} />
        </FullHeightStackingContainer>
    )
}

export default SuppliersList

