import React, { useMemo } from 'react'
import { createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getPaginationRowModel, getSortedRowModel, Row, useReactTable } from "@tanstack/react-table"
import { IRiskStatisticMeasurementDataDto, IRiskStatisticMeasurmentDto } from '../../../../../../api/risks'
import { useGetQueryByParams } from '../../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../../api'
import Loading from '../../../../../common/Loading'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useTranslation } from 'react-i18next'
import TableReact, { FooterLabel } from '../../../../../wrappers/TableReact'
import { Grid, GridRow, Header } from 'semantic-ui-react'
import { transformToStatisticsYearMonthRow } from '../../../../../../utils/Mappings/Issues'
import CustomBarLabel from '../../../../../modules/issues/IssueStatisticPage/CustomBarLabel'

interface IRiskStatisticExpandedProps {
    riskId: number
    row: Row<IRiskStatisticMeasurmentDto>
}
const RiskStatisticExpanded = ({ riskId, row }: IRiskStatisticExpandedProps) => {
    const { t } = useTranslation()
    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.risks.getRiskStatisticMeasurmenData, "riskStatisticMeasurmentListData", { riskId: riskId, id: row.original.id })
    const columnHelper = createColumnHelper<IRiskStatisticMeasurementDataDto>()

    const riskStatisticExpandedColumns = useMemo(
        () => [
            columnHelper.accessor('year', {
                id: 'year',
                header: t('year') as any,
            }),
            columnHelper.accessor('monthWeek', {
                id: 'monthWeek',
                header: t('monthWeek') as any,
            }),
            columnHelper.accessor('value', {
                id: 'value',
                header: t('value') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.value)
                        .reduce((a: number | null, c: number | null) => (a ? a : 0) + (c ? c : 0), 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
        ], [columnHelper, t])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: riskStatisticExpandedColumns,
        enableGlobalFilter: false,
        enableGrouping: false,
        enableColumnFilters: false,
        enableHiding: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    const buildTitleKey = (cur: IRiskStatisticMeasurementDataDto,
    ) => {
        const keyParts: string[] = [];

        if (cur.year) {
            keyParts.push(cur.year.toString());
        }
        if (cur.monthWeek) {
            keyParts.push(`${cur.monthWeek}`);
        }
        return keyParts.join("-");
    };

    if (isLoading || !data) return <Loading />

    const mappedData = {
        costBarData: transformToStatisticsYearMonthRow(
            data ? data : [],
            x => buildTitleKey(x),
            x => ({ value: x.value })
        ),
    };

    return (
        <Grid stackable style={{ marginTop: "2em", padding: "2em" }}>
            <GridRow columns={1}>
                <Grid.Column>
                    <Header as='h3' textAlign='center'>{t('table')}</Header>
                </Grid.Column>
                <Grid.Column >
                    <TableReact table={table}
                        canExport={false}
                    />
                </Grid.Column></GridRow>
            <GridRow columns={1}>
                <Grid.Column>
                    <Header as='h3' textAlign='center'>{t('barChart')}</Header>
                </Grid.Column>
                <Grid.Column>
                    {(mappedData?.costBarData?.rows?.length ?? 0) > 0 ? (
                        <ResponsiveContainer height={500}
                        >
                            <BarChart data={mappedData?.costBarData?.rows}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <CartesianGrid vertical={false} />
                                <Legend layout="horizontal" />
                                <Bar dataKey="value" isAnimationActive={false} name={t('value')} stackId="a" fill="#3377A1">
                                    <LabelList dataKey="value" content={CustomBarLabel} />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    ) : null}
                </Grid.Column>
            </GridRow>
        </Grid>
    )
}
export default RiskStatisticExpanded