import React, { useRef, useState } from 'react'
import { AlertCircle, CheckCircle, Info, Printer } from 'react-feather'
import { useTranslation } from 'react-i18next'
import ReactToPrint from 'react-to-print'
import { Button, Card, Checkbox, Container, Dimmer, Grid, List, Loader, Table } from 'semantic-ui-react'
import { formatDate } from '../../../../actions/util'
import { DetailedPosition, useMyPositions, useMySkills } from '../../../../hooks/skills'
import {
    Course,
    CourseStatusComplete,
    CourseStatusEvalulate,
    useMyCourses,
} from '../../../../hooks/skills/course'
import Files from '../../../common/files'
import FlexBox from '../../../common/flexBox'
import ResponsiveContent, { ResponsiveTarget } from '../../../common/responsiveContent'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import EvaulateEducationModal from './evaulateCourseModal'
import PositionInfoModal from './positionInfoModal'
import DocumentSectionModule from '../../docs/viewPage/DocumentSectionModule'
import { getState } from '../../../../state'

const getCardColor = (education: Course) => {
    switch (education.status) {
        case CourseStatusComplete:
            return 'green'
        case CourseStatusEvalulate:
            return 'red'
        default:
            return undefined
    }
}

const MySkills: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const printRef = useRef(null)
    const {
        state: { userProfile },
    } = getState()
    const [selectedPosition, setSelectedPosition] = useState<DetailedPosition>()
    const [selectedCourse, setSelectedCourse] = useState<Course>()

    const { data: skills, isValidating: skillsLoading } = useMySkills()
    const { data: positions, isValidating: positionsLoading } = useMyPositions()
    const { data: courses } = useMyCourses()

    return (
        <Container>
            <EvaulateEducationModal course={selectedCourse} />
            <PositionInfoModal position={selectedPosition} />
            <FlexBox justifyContent="end">
                <ReactToPrint
                    trigger={() => (
                        <Button
                            type="button"
                            icon={<SemanticFeatherIcon FeatherIcon={Printer} />}
                            content={t('print')}
                        />
                    )}
                    content={() => printRef.current!}
                    pageStyle="padding: 20px;"
                />
            </FlexBox>

            <Dimmer
                active={
                    (skillsLoading && !skills?.length) || (positionsLoading && !positions?.length)
                }
            >
                <Loader />
            </Dimmer>

            <div ref={printRef}>
                <Grid stackable>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <DocumentSectionModule title={t('skills')}>
                                <Table unstackable basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>{t('skill')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('validFrom')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('validTo')}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {skills?.map(x => (
                                            <Table.Row key={x.id}>
                                                <Table.Cell>{x.name}</Table.Cell>
                                                <Table.Cell>{formatDate(x.validFrom)}</Table.Cell>
                                                <Table.Cell>{formatDate(x.validTo)}</Table.Cell>
                                            </Table.Row>
                                        ))}

                                        {!skills?.length && !skillsLoading && (
                                            <Table.Row>
                                                <Table.Cell colSpan="3" textAlign="center">
                                                    {t('noSkills')}
                                                </Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                </Table>
                            </DocumentSectionModule>
                        </Grid.Column>
                        <Grid.Column>
                            <DocumentSectionModule title={t('positions')}>
                                <Table unstackable basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell>{t('position')}</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>{t('isRegular')}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {positions?.map(x => (
                                            <Table.Row key={x.id}>
                                                <Table.Cell collapsing>
                                                    <Button
                                                        type="button"
                                                        icon={
                                                            <SemanticFeatherIcon
                                                                FeatherIcon={Info}
                                                                centerIcon={true}
                                                                size="24px"
                                                            />
                                                        }
                                                        size="tiny"
                                                        onClick={() =>
                                                            setSelectedPosition({ ...x })
                                                        }
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>{x.name}</Table.Cell>
                                                <Table.Cell verticalAlign="top" textAlign='center'>
                                                    <Checkbox checked={x.isRegular} />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}

                                        {!positions?.length && !positionsLoading && (
                                            <Table.Row>
                                                <Table.Cell colSpan="2" textAlign="center">
                                                    {t('noPositions')}
                                                </Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                </Table>
                            </DocumentSectionModule>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <DocumentSectionModule title={t('files')}>
                    <Files
                        module="CompetenceUser"
                        collectionId={userProfile?.sub}
                        mayAutoUpload={true}
                        disableDelete={true}
                    />
                </DocumentSectionModule>

                {!!courses?.length && (
                    <DocumentSectionModule title={t('plannedCompletedEducations')}>
                        <ResponsiveContent target={ResponsiveTarget.Desktop}>
                            <span />
                            <Table basic="very">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{t('status')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('education')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('heldBy')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('location')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('scope')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('plannedDate')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('dateCompleted')}</Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {courses?.map(x => (
                                        <Table.Row
                                            key={x.id}
                                            negative={x.status === CourseStatusEvalulate}
                                            positive={x.status === CourseStatusComplete}
                                        >
                                            <Table.Cell>
                                                {t(x.status?.toLowerCase() ?? '')}
                                            </Table.Cell>
                                            <Table.Cell>{x.name}</Table.Cell>
                                            <Table.Cell>{x.heldBy}</Table.Cell>
                                            <Table.Cell>{x.location}</Table.Cell>
                                            <Table.Cell>{x.scope}</Table.Cell>
                                            <Table.Cell>{formatDate(x.datePlanned)}</Table.Cell>
                                            <Table.Cell>{formatDate(x.dateCompleted)}</Table.Cell>
                                            <Table.Cell>
                                                {x.status === CourseStatusEvalulate && (
                                                    <Button
                                                        type="button"
                                                        content={t('utvärdera')}
                                                        primary
                                                        size="small"
                                                        onClick={() => setSelectedCourse({ ...x })}
                                                    />
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </ResponsiveContent>
                        <ResponsiveContent target={ResponsiveTarget.Phone}>
                            {courses?.map(x => (
                                <Card key={`card-${x.id}`} fluid color={getCardColor(x)}>
                                    <Card.Content>
                                        <Card.Header>
                                            <FlexBox alignItems="center" gap="5px">
                                                {x.status === CourseStatusEvalulate && (
                                                    <AlertCircle
                                                        color="red"
                                                        size="24px"
                                                        style={{ marginLeft: '-5px' }}
                                                    />
                                                )}
                                                {x.status === CourseStatusComplete && (
                                                    <CheckCircle
                                                        color="green"
                                                        size="24px"
                                                        style={{ marginLeft: '-5px' }}
                                                    />
                                                )}
                                                {x.name}
                                            </FlexBox>
                                        </Card.Header>
                                    </Card.Content>
                                    <Card.Content>
                                        <List>
                                            {x.heldBy && (
                                                <List.Item
                                                    description={t('heldBy')}
                                                    content={x.heldBy}
                                                />
                                            )}
                                            {x.location && (
                                                <List.Item
                                                    description={t('location')}
                                                    content={x.location}
                                                />
                                            )}
                                            {x.scope && (
                                                <List.Item
                                                    description={t('scope')}
                                                    content={x.scope}
                                                />
                                            )}
                                            {x.datePlanned && (
                                                <List.Item
                                                    description={t('plannedDate')}
                                                    content={formatDate(x.datePlanned)}
                                                />
                                            )}
                                            {x.dateCompleted && (
                                                <List.Item
                                                    description={t('dateCompleted')}
                                                    content={formatDate(x.dateCompleted)}
                                                />
                                            )}
                                        </List>
                                    </Card.Content>
                                    {x.status === CourseStatusEvalulate && (
                                        <Card.Content textAlign="center">
                                            <Button
                                                type="button"
                                                content={t('utvärdera')}
                                                primary
                                                size="small"
                                                onClick={() => setSelectedCourse({ ...x })}
                                            />
                                        </Card.Content>
                                    )}
                                </Card>
                            ))}
                        </ResponsiveContent>
                    </DocumentSectionModule>
                )}
            </div>
        </Container>
    )
}

export default MySkills
