import { Button, Container, Form, Grid, GridColumn, GridRow, Header } from 'semantic-ui-react'
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import * as API from '../../../../api';
import { useForm } from 'react-hook-form';
import ControlInput from '../../../fields/ControlInput';
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon';
import { Save } from 'react-feather';
import { getState } from '../../../../state';
import { useMutation } from 'react-query';
import { handleAxiosError } from '../../../../actions/error';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IChangePasswordRequest } from '../../../../api/admin';
import React from 'react';

const ChangePassword = () => {

    const { t } = useTranslation()
    const {
        dispatch,
        } = getState()

    const userNewAuthSchema = z.object({
        oldPassword: z.string(),
        newPassword: z.string()
            .min(12, { message: t('passwordLength') })
            .regex(/[A-Z]/, t('passwordUpperCase'))
            .regex(/[a-z]/, t('passwordLowerCase'))
            .regex(/[0-9]/, t('passwordNumber'))
            .regex(/[^A-Za-z0-9]/, t('passwordSpecialChar')),
        confirmNewPassword: z.string().min(1, )
          }).refine((data) => data.newPassword === data.confirmNewPassword, {
            path: ['confirmNewPassword'],
            message: t('passwordsDoNotMatch'),
          });

    const { control, handleSubmit } = useForm<IChangePasswordRequest>({
        resolver: zodResolver(userNewAuthSchema),
    })

    const onUserAuthRequest = async (data: IChangePasswordRequest) => {
        return await API.admin.changePassword(data)
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onUserAuthRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error);
        },
        onSuccess: () => {
            toast.success(t('saved'));
        },
    });

    return (
        <Container>
            <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
                <Grid stackable>
                    <GridRow columns="1">
                        <GridColumn>
                            <Header>
                                {t('userAuthFormHeader')}
                            </Header>
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="oldPassword" label={t('oldPassword')} placeholder={t('oldPassword')} control={control} disabled={isSaving} type="password" />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="newPassword" label={t('newPassword')} placeholder={t('newPassword')} control={control} disabled={isSaving} type="password" />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1">
                        <GridColumn>
                            <ControlInput name="confirmNewPassword" label={t('confirmNewPassword')} placeholder={t('confirmNewPassword')} control={control} disabled={isSaving} type="password" />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="1" textAlign='right'>
                        <GridColumn>
                            <Button
                                type="submit"
                                primary
                                disabled={isSaving}
                                icon
                                labelPosition='left'>
                                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon/>
                                    {t('save')}
                            </Button>
                        </GridColumn>
                    </GridRow>

                </Grid>
            </Form>
        </Container>
    )
}

export default ChangePassword;