import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import ReactFlow, {
    Background,
    Controls,
    Node,
    Edge,
    BackgroundVariant,
    Panel,
    useReactFlow,
    getNodesBounds,
    getViewportForBounds,
} from "reactflow";
import "reactflow/dist/style.css";
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams';
import * as API from '../../../../../api'
import Loading from '../../../../common/Loading';
import { IOrganizationChartDto } from '../../../../../api/skills';
import ELK, { ElkNode } from "elkjs";
import ModalWrapper from '../../../../wrappers/ModalWrapper';
import PositionPrintView from './PositionPrintView';
import { toPng } from 'html-to-image';


const elk = new ELK();

export interface TransformedData {
    nodes: Node[];
    edges: Edge[];
}

const transformDataToNodesAndEdgesWithLayout = async (
    data: IOrganizationChartDto[]
): Promise<{ nodes: Node[]; edges: Edge[] }> => {
    const nodes: Node[] = [];
    const edges: Edge[] = [];

    const traverse = (items: IOrganizationChartDto[], parent: IOrganizationChartDto | null = null) => {
        items.forEach((item) => {
            nodes.push({
                id: item.id.toString(),
                data: { label: item.name, description: item.description, requiredSkills: item.requiredSkills, users: item.users },
                position: { x: 0, y: 0 },
            });

            if (parent) {
                edges.push({
                    id: `e${parent.id}-${item.id}`,
                    source: parent.id.toString(),
                    target: item.id.toString(),
                });
            }

            if (item.subRows && item.subRows.length > 0) {
                traverse(item.subRows, item);
            }
        });
    };

    traverse(data);

    const elkGraph: ElkNode = {
        id: "root",
        children: nodes.map((node) => ({
            id: node.id,
            width: 150,
            height: 50,
        })),
        edges: edges.map((edge) => ({
            id: edge.id,
            sources: [edge.source],
            targets: [edge.target],
        })),
    };

    const layout = await elk.layout(elkGraph, {
        layoutOptions: {
            "elk.algorithm": "layered",
            "elk.direction": "DOWN",
            "nodePlacement.strategy": "NETWORK_SIMPLEX",
            "elk.layered.spacing.nodeNodeBetweenLayers": "50",
            "elk.spacing.nodeNode": "50",
            "elk.layered.spacing.nodeNodeBetweenConnectedComponents": "100",
            "elk.layered.considerModelOrder.strategy": "NODES_AND_EDGES"
        },
    });

    layout.children?.forEach((child) => {
        const node = nodes.find((n) => n.id === child.id);
        if (node && child.x != null && child.y != null) {
            node.position = { x: child.x, y: child.y };
        }
    });

    return { nodes, edges };
};


function downloadImage(dataUrl: string) {
    const a = document.createElement("a");
    a.setAttribute("download", "organization-chart.png");
    a.setAttribute("href", dataUrl);
    a.click();
}

const OrgChart = () => {
    const { t } = useTranslation()
    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.skills.getOrganization, "organizationChart")
    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);
    const [selectedNode, setSelectedNode] = useState<Node | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { getNodes } = useReactFlow();
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const loadLayout = async () => {
            if (data) {
                const { nodes, edges } = await transformDataToNodesAndEdgesWithLayout(data);
                setNodes(nodes);
                setEdges(edges);
            }
        };

        loadLayout();
    }, [data]);

    const handleNodeClick = (node: Node) => {
        setSelectedNode(node);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedNode(null);
    };

    const handleDownload = () => {
        const viewport = reactFlowWrapper.current?.querySelector(".react-flow__viewport") as HTMLElement;
        if (!viewport) {
            console.error("React Flow viewport not found");
            return;
        }
        const nodesBounds = getNodesBounds(getNodes());

        const padding = 50;
        const dynamicWidth = Math.ceil(nodesBounds.width + padding * 2);
        const dynamicHeight = Math.ceil(nodesBounds.height + padding * 2);

        const calculatedViewport = getViewportForBounds(
            nodesBounds,
            dynamicWidth,
            dynamicHeight,
            0.5,
            2
        );

        toPng(viewport, {
            backgroundColor: "#1a365d",
            width: dynamicWidth,
            height: dynamicHeight,
            style: {
                width: `${dynamicWidth}px`,
                height: `${dynamicHeight}px`,
                transform: `translate(${calculatedViewport.x}px, ${calculatedViewport.y}px) scale(${calculatedViewport.zoom})`,
            },
        })
            .then(downloadImage)
            .catch((error: any) => console.error("Error generating image:", error));
    };

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <FullHeightStackingContainer>

            <Message info>
                <Message.Header>
                    {t('skillsAdmin.orgChartHeader')}
                </Message.Header>
                {t('skillsAdmin.orgChartMessageBody')}
            </Message>
            <Header dividing>{t('organizationChart')}</Header>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                fitView
                panOnScroll={true}
                onNodeClick={(_, node) => handleNodeClick(node)}
                ref={reactFlowWrapper}
            >
                <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
                <Controls />
                <Panel position="top-right">
                    <Button type="button" onClick={handleDownload}>{t('downloadImage')}</Button>
                </Panel>
            </ReactFlow>

            {selectedNode && <ModalWrapper title={selectedNode.data.label} open={isModalOpen} onClose={closeModal} >
                <PositionPrintView selectedPosition={selectedNode.data} />
            </ModalWrapper>}
        </FullHeightStackingContainer>
    )
}

export default OrgChart
