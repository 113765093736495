import { put, get } from "../restClient";

export interface IStatisticConfigurationRequest {
    laborCost: number | null
    deviationTypeYear: boolean
    deviationTypeMonth: boolean
    qualityDeviationCost: boolean
    deviationsReportedInTime: boolean
    remedyEffecieny: boolean
    accidentTypeYearBar: boolean
    accidentTypeYearPie: boolean
}
export interface ICompanyDeviationSettingsRequest {
    showDeviationPopUp: boolean
}
export interface IStatisticConfiguration {
    avvAr1: boolean | null

    avvAr2: boolean | null

    avvMan: boolean | null

    avvTid: boolean | null

    avvEffekt: boolean | null

    olyAr1: boolean | null

    avvKost: boolean | null

    forbIdeAr: boolean | null

    forbIdeMan: boolean | null

    forbAktAr: boolean | null

    forbAktMan: boolean | null

    forbEffekt: boolean | null
}

export const getStatisticsConfiguration = () => get<IStatisticConfiguration>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/StatisticsConfiguration`)

export const editStatisticConfiguration = (data: IStatisticConfigurationRequest) => put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/StatisticsConfiguration`, data)

export const editCompanyDeviationSettings = (data: ICompanyDeviationSettingsRequest) => put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Company/Deviation/settings`, data)