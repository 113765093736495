import * as API from '../../../../api';
import React, { useMemo, useState } from "react";
import { Button, Container, Header, Popup } from "semantic-ui-react";
import {
    ColumnDef, createColumnHelper, getCoreRowModel, getFacetedRowModel,
    getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel,
    getSortedRowModel, useReactTable
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Edit, Trash2 } from 'react-feather';
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams';
import { IDepartmentDto } from '../../../../api/admin';
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon';
import Loading from '../../../common/Loading';
import TableReact from '../../../wrappers/TableReact';
import ModalWrapper from '../../../wrappers/ModalWrapper';
import DepartmentForm from '../../../forms/DepartmentForm';
import { ButtonContainer } from '../../../surveys/surveyPage/surveyBase';
import { useDestroyDepartment } from './useDestroyDepartment';

const DepartmentList = () => {

    const { t } = useTranslation()
    const {
        isLoading,
        data: departmentList,
        refresh,
    } = useGetQueryByParams(true, API.admin.getMyDepartments, "departmentList");

    const [selectedDepartment, setSelectedDepartment] = useState<IDepartmentDto | null>(null);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const { isDestroying, onDestroy } = useDestroyDepartment(refresh)

    const handleEditClick = (department: IDepartmentDto) => {
        setSelectedDepartment(department);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedDepartment(null);
        setEditModalOpen(false);
    };

    const handleAddClick = () => {
        setSelectedDepartment(null);
        setAddModalOpen(true);
    };

    const closeAddModal = () => {
        setAddModalOpen(false);
    };

    const columnHelper = createColumnHelper<IDepartmentDto>();

    const columns = useMemo<ColumnDef<IDepartmentDto>[]>(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 80,
                cell: ({ row }) => (
                    <ButtonContainer>
                        {row.original.isUsed ? (
                            <Popup
                                content={t('deleteValueMessage', { value: row.original.name })}
                                trigger={
                                    <span>
                                        <Button
                                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                                            negative
                                            title={t('delete')}
                                            disabled
                                        />
                                    </span>
                                }
                            />
                        ) : (
                            <Button
                                icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                                negative
                                title={t('delete')}
                                disabled={isDestroying}
                                onClick={() => onDestroy({ confirmText: t('confirm.deleteValue', { value: row.original.name }), id: row.original.id })}
                            />
                        )}
                        <Button
                            title={t("edit")}
                            type="button"
                            disabled={isDestroying}
                            onClick={() => handleEditClick(row.original)}
                            icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                        />
                    </ButtonContainer>
                ),
            }),
            {
                accessorKey: "name",
                header: t("department"),
            },
            {
                accessorKey: "description",
                header: t("description"),
                size: 500,
            },
        ],
        [t, columnHelper, isDestroying, onDestroy]
    );

    const table = useReactTable({
        data: departmentList || [],
        columns,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    if (isLoading) {
        return <Loading />
    }

    return (
        <Container>
            <Header dividing content={t('departmentListHeader')} />
            <TableReact table={table} canExport={true} onAddCallback={handleAddClick} />

            <ModalWrapper title={t('editDepartment')} open={isEditModalOpen} onClose={closeEditModal} >
                <DepartmentForm selectedDepartment={selectedDepartment} refresh={refresh} onClose={closeEditModal}
                />
            </ModalWrapper>

            <ModalWrapper title={t('addDepartment')} open={isAddModalOpen} onClose={closeAddModal} >
                <DepartmentForm selectedDepartment={null} refresh={refresh} onClose={closeAddModal}
                />
            </ModalWrapper>
        </Container>
    );
};

export default DepartmentList;