import { useTranslation } from "react-i18next";
import * as API from '../../../../api';
import { useGetQueryByParams } from "../../../../hooks/useGetQueryByParams";
import { useMemo, useState } from "react";
import { ILocationDto } from "../../../../api/admin";
import {
    ColumnDef, createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable
} from "@tanstack/react-table";
import { ButtonContainer } from "../../../surveys/surveyPage/surveyBase";
import React from "react";
import { Button, Container, Header, Popup } from "semantic-ui-react";
import SemanticFeatherIcon from "../../../icons/SemanticFeatherIcon";
import { Edit, Trash2 } from "react-feather";
import Loading from "../../../common/Loading";
import TableReact from "../../../wrappers/TableReact";
import ModalWrapper from "../../../wrappers/ModalWrapper";
import { useDestroyLocation } from "./useDestroyLocation";
import LocationForm from "../../../forms/LocationForm";

const LocationList = () => {

    const { t } = useTranslation()
    const {
        isLoading,
        data: locationList,
        refresh,
    } = useGetQueryByParams(true, API.admin.getMyLocations, "locationList");

    const [selectedLocation, setSelectedLocation] = useState<ILocationDto | null>(null);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const { isDestroying, onDestroy } = useDestroyLocation(refresh);

    const handleEditClick = (location: ILocationDto) => {
        setSelectedLocation(location);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedLocation(null);
        setEditModalOpen(false);
    };

    const handleAddClick = () => {
        setSelectedLocation(null);
        setAddModalOpen(true);
    };

    const closeAddModal = () => {
        setAddModalOpen(false);
    };

    const columnHelper = createColumnHelper<ILocationDto>();

    const columns = useMemo<ColumnDef<ILocationDto>[]>(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 80,
                cell: ({ row }) => (
                    <ButtonContainer>
                        {row.original.isUsed ? (
                            <Popup
                                content={t('deleteValueMessage', { value: row.original.name })}
                                trigger={
                                    <span>
                                        <Button
                                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                                            negative
                                            title={t('delete')}
                                            disabled
                                        />
                                    </span>
                                }
                            />
                        ) : (
                            <Button
                                icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                                negative
                                title={t('delete')}
                                disabled={isDestroying}
                                onClick={() => onDestroy({ confirmText: t('confirm.deleteValue', { value: row.original.name }), locationId: row.original.id })}
                            />
                        )}
                        <Button
                            title={t("edit")}
                            type="button"
                            disabled={isDestroying}
                            onClick={() => handleEditClick(row.original)}
                            icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                        />
                    </ButtonContainer>
                ),
            }),
            {
                accessorKey: "name",
                header: t("location"),

            },
            {
                accessorKey: "description",
                header: t("description"),
                size: 500,
            },
        ],
        [t, columnHelper, isDestroying, onDestroy]
    );

    const table = useReactTable({
        data: locationList || [],
        columns,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    if (isLoading) {
        return <Loading />
    }

    return (
        <Container>
            <Header dividing content={t('locationListHeader')} />
            <TableReact table={table} canExport={true} onAddCallback={handleAddClick} />

            <ModalWrapper title={t('editLocation')} open={isEditModalOpen} onClose={closeEditModal} >
                <LocationForm selectedLocation={selectedLocation} refresh={refresh} onClose={closeEditModal}
                />
            </ModalWrapper>

            <ModalWrapper title={t('addLocation')} open={isAddModalOpen} onClose={closeAddModal} >
                <LocationForm selectedLocation={null} refresh={refresh} onClose={closeAddModal}
                />
            </ModalWrapper>
        </Container>
    );
};

export default LocationList;