import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../../state';
import FormActions from '../../../FormActions';
import { handleAxiosError } from '../../../../../actions/error';
import * as API from '../../../../../api'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams';
import Loading from '../../../../common/Loading';
import ControlDropdown from '../../../../fields/ControlDropdown';

export interface IRiskLawFormProps {
    riskId: number
    onCancel: () => void
    refresh: () => void
}

export const riskLawRequestSchema = z.object({
    lawId: z.coerce.number().min(1, "required"),
});

const RiskLawForm = ({ riskId, onCancel, refresh }: IRiskLawFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.laws.getAll, "laws")

    const { control, handleSubmit } = useForm<{ lawId: number }>({
        resolver: zodResolver(riskLawRequestSchema),
    });

    const addGoalConnection = async (data: { lawId: number }) => {
        return await API.risks.createRiskLaw(riskId, data.lawId)
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(addGoalConnection, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })
    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate >
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn >
                        <ControlDropdown
                            name="lawId"
                            label={t("laws")}
                            control={control}
                            disabled={isSaving}
                            collection={data.map(d => ({ key: d.id, text: `${d.designation} - ${d.name}`, value: d.id }))} />
                    </GridColumn>
                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default RiskLawForm;