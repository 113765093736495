import React from 'react'
import styled from 'styled-components';
import { IRiskLimitDto } from '../../../../api/risks';

interface IRiskValueCell {
    value: number | null
    limits?: IRiskLimitDto
}

const handleColors = (value: number | null) => {
    if (value !== null) {
        if (value <= -15 && value <= 25) return "red;";
        if (value <= -10 && value <= 14) return "orange;";
        if (value <= -5 && value <= 9) return "yellow;";
        if (value <= -3 && value <= 4) return "teal;";
        if (value <= -1 && value <= 2) return "green;";
        if (value >= 1 && value <= 25) return "blue; color:white;";
    }
    return "white;";
}
const handleFontWeight = (value: number | null, limits?: IRiskLimitDto) => {
    if (value !== null && limits && limits?.negative !== null && value <= limits?.negative) {
        return "bold";
    }
    if (value !== null && limits && limits?.positive !== null && value >= limits?.positive) {
        return "bold";
    }
    return "normal";
}

const CustomColoredSpan = styled.span<{ value: number | null, limits?: IRiskLimitDto }>`
    background-color: ${p => handleColors(p.value)}
    font-weight: ${p => handleFontWeight(p.value, p.limits)};
    padding: 0.7em;
    text-align:center;
    border-radius: 50%;
`;

const RiskValueCell = ({ value, limits }: IRiskValueCell) => {
    return (
        <CustomColoredSpan value={value} limits={limits}>{value}</CustomColoredSpan>
    )
}

export default RiskValueCell;
