import React, { useMemo, useState } from "react";
import { Button, Container, Header } from "semantic-ui-react";
import TableReact from "../../../wrappers/TableReact";
import ModalWrapper from "../../../wrappers/ModalWrapper";
import { useTranslation } from "react-i18next";
import { IUserIce } from "../../../../api/admin";
import { useGetQueryByParams } from "../../../../hooks/useGetQueryByParams";
import { ColumnDef, createColumnHelper, getCoreRowModel, getExpandedRowModel, getFacetedRowModel, 
    getFacetedUniqueValues, getFilteredRowModel, getGroupedRowModel, getPaginationRowModel, 
    getSortedRowModel, useReactTable } from "@tanstack/react-table";
import SemanticFeatherIcon from "../../../icons/SemanticFeatherIcon";
import { Edit } from "react-feather";
import * as API from '../../../../api';
import Loading from "../../../common/Loading";
import IceForm from "../../../forms/IceForm";

const IceList = () => {

    const { t } = useTranslation()

    const {
        isLoading,
        data: iceList,
        refresh,
    } = useGetQueryByParams(true, API.admin.getIceList, "iceList");

    const [selectedUser, setSelectedUser] = useState<IUserIce | null >(null);
    
    const [isModalOpen, setModalOpen] = useState(false);

    const handleEditClick = (user: IUserIce) => {
        setSelectedUser(user);
        setModalOpen(true);
    }

    const closeModal = () => {
        setSelectedUser(null);
        setModalOpen(false);
    }

    const columnHelper = createColumnHelper<IUserIce>();

    const columns = useMemo<ColumnDef<IUserIce>[]>(
        () => [
            columnHelper.display({
                id:"actions",
                enableHiding: false,
                enableGrouping: false,
                size: 50,
                minSize: 50,
                maxSize: 50,
                cell: ({ row }) => (
                    <Button
                        title={t("edit")}
                        type="button"
                        onClick={() => handleEditClick(row.original)}
                        icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                    />
                ),
            }),
            {
                accessorKey: "name",
                header: t('name'),
            },
            {
                accessorKey: "userIce1",
                header: t('userIce1'),
            },
            {
                accessorKey: "userIce2",
                header: t('userIce2'),
            },
        ],
        [t, columnHelper]
    );

     const table = useReactTable({
           data: iceList || [],
           columns,
           enableGrouping: false,
           getExpandedRowModel: getExpandedRowModel(),
           getCoreRowModel: getCoreRowModel(),
           getFilteredRowModel: getFilteredRowModel(),
           getSortedRowModel: getSortedRowModel(),
           getPaginationRowModel: getPaginationRowModel(),
           getFacetedRowModel: getFacetedRowModel(),
           getFacetedUniqueValues: getFacetedUniqueValues(),
       });

        if (isLoading) {
            return <Loading />
        }

    return (
        <Container>
            <Header dividing content={t('iceListHeader')} />
            <TableReact table={table} canExport={true} /> 
            <ModalWrapper title={t('editIce')} open={isModalOpen} onClose={closeModal}>
                <IceForm userIce={selectedUser} refresh={refresh} onClose={closeModal} />  
            </ModalWrapper> 
        </Container>
    );
};

export default IceList;