import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../../state';
import * as API from '../../../../../api'
import { handleAxiosError } from '../../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import FormActions from '../../../FormActions';
import ControlDropdown from '../../../../fields/ControlDropdown';
import { IRiskStatisticMeasurmentDto } from '../../../../../api/risks';
import { mockedRiskStatisticMeasurment } from '../../../../../utils/Mappings/Risk';
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams';
import Loading from '../../../../common/Loading';

interface IRiskStatisticMeasurementFormProps {
    isNew: boolean
    onCancel: () => void
    refresh: () => void
    selectedMeasurement?: IRiskStatisticMeasurmentDto
    riskId: number
}

export const statisticMeasurementSchema = z.object({
    id: z.number(),
    periodMeasurement: z.string().optional().nullable(),
    detailedType: z.string().optional().nullable(),
    unit: z.string().optional().nullable(),
    inRelation: z.string().optional().nullable(),
    inRelationUnit: z.string().optional().nullable(),
    matId: z.coerce.number().optional().nullable(),
    mafId: z.coerce.number().optional().nullable(),
});

const RiskStatisticMeasurementForm = ({ refresh, onCancel, isNew, selectedMeasurement, riskId }: IRiskStatisticMeasurementFormProps) => {
    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<IRiskStatisticMeasurmentDto>({
        resolver: zodResolver(statisticMeasurementSchema),
        defaultValues: selectedMeasurement ? selectedMeasurement : mockedRiskStatisticMeasurment
    });

    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.risks.getRiskStatisticMeasurmentTypes, "types")

    const {
        isLoading: isLoadingRelations,
        data: relations,
    } = useGetQueryByParams(true, API.risks.getRiskStatisticMeasurmentRelations, "relations")

    const onKeyFigureRequest = async (data: IRiskStatisticMeasurmentDto) => {
        if (isNew) {
            return await API.risks.createRiskStatisticMeasurment(riskId, data)
        } else {
            return await API.risks.editRiskStatisticMeasurment(riskId, data)
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onKeyFigureRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            if (data) {
                toast.success(t("saved"))
                refresh()
                onCancel()
            }

        },
    })
    if (isLoading || !data || isLoadingRelations || !relations) {
        return <Loading />
    }
    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="2">
                    <GridColumn >
                        <GridColumn>
                            <ControlDropdown
                                required
                                name="matId"
                                clearable
                                label={t('matId')}
                                control={control}
                                disabled={isSaving}
                                collection={data.map(d => ({ value: d.id, text: d.detailedType })) ?? []} />
                        </GridColumn>
                    </GridColumn>
                    <GridColumn >
                        <ControlDropdown
                            required
                            name="mafId"
                            label={t('mafId')}
                            clearable
                            control={control}
                            disabled={isSaving}
                            collection={relations.map(d => ({ value: d.id, text: d.inRelation })) ?? []} />
                    </GridColumn>
                </GridRow>

            </Grid>
            <FormActions
                onCancel={onCancel}
                disabled={isSaving}
            />
        </Form>
    );
};

export default RiskStatisticMeasurementForm;
