import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Header } from 'semantic-ui-react'
import { ColumnDef, createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Trash2 } from 'react-feather'
import TableReact from '../../../wrappers/TableReact'
import { IRiskGoalDto } from '../../../../api/risks'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import ModalWrapper from '../../../wrappers/ModalWrapper'
import { useIsInRole } from '../../../../hooks/role'
import { AdminRoles } from '../../../../auth/roles'
import { useDeleteRiskGoal } from './useDeleteRiskGoal'
import RiskGoalForm from './RiskGoalForm'
import { isInPageRenderMode } from '../../../../actions/util'

interface IRiskGoalTabProps {
    riskId: number
}
const RiskGoalTab = ({ riskId }: IRiskGoalTabProps) => {
    const { t } = useTranslation()
    const { isLoading, data, refresh } = useGetQueryByParams(!!riskId, API.risks.getAllRiskGoals, "riskGoals", riskId)
    const [open, setOpen] = useState(false);
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.RisksAdminRole)
    const { isDestroying, onDestroy } = useDeleteRiskGoal(refresh)
    const columnHelper = createColumnHelper<IRiskGoalDto>()
    const riskAssessmentColumns: ColumnDef<IRiskGoalDto, any>[] = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                size: 25,
                cell: ({ row }) =>
                    <>
                        {isAdmin && <Button
                            title={t('delete')}
                            onClick={() => onDestroy({ confirmText: t('confirm.deleteValue', { value: row.original.name }), riskId: riskId, goalId: row.original.goalId })}
                            type='button'
                            color='red'
                            disabled={isDestroying}
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />}
                    </>
            }),
            columnHelper.accessor('name', {
                id: "name",
                header: t('name'),
                size: 500,
                cell: ({ row }) => <a href={isInPageRenderMode() ? `/goals/${row.original.goalId}` : `/mal/malModul.aspx#/${row.original.goalId}`}>{row.original.name}</a>
            }),
        ], [columnHelper, t, riskId, isDestroying, onDestroy, isAdmin])
    const table = useReactTable({
        data: data || [],
        columns: riskAssessmentColumns,
        columnResizeMode: "onChange",
        enableGlobalFilter: false,
        enableGrouping: false,
        enableColumnFilters: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })
    if (isLoading || !data) {
        return <Loading />
    }
    return (
        <>
            <Header dividing content={t('goal')} />
            <TableReact table={table}
                onAddCallback={() => setOpen(true)}
                canExport={false}
                overflowable
            />
            <ModalWrapper title={t('registerNew')} open={open} onClose={() => setOpen(false)}>
                <RiskGoalForm onCancel={() => setOpen(false)} refresh={refresh} riskId={riskId} />
            </ModalWrapper>
        </>
    )
}

export default RiskGoalTab
