import React from "react"
import { Control, Controller, FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormInput, Popup } from "semantic-ui-react"
import SemanticFeatherIcon from "../../icons/SemanticFeatherIcon"
import { HelpCircle } from "react-feather"
import FlexBox from "../../common/flexBox"
import styled from "styled-components"

interface IControlInputProps<T extends FieldValues = any> {
    label: string
    name: string
    disabled: boolean
    control: Control<T>
    type?: string
    required?: boolean
    placeholder?: string
    overrideOnChange?: (value: any) => void
    explanatoryText?: string
}

const CustomInputLabel = styled.label<{ required: boolean }>`
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
    ${props => props.required && `
    :after {
display: inline-block;
    vertical-align: top;
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: #db2828;
    };
    `}
    
`

const ControlInput = ({
    name,
    label,
    disabled,
    control,
    type = "text",
    required = false,
    placeholder,
    overrideOnChange,
    explanatoryText

}: IControlInputProps) => {
    const { t } = useTranslation()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormInput
                        label={explanatoryText ? <FlexBox gap="6px"><CustomInputLabel required={required}>{label}</CustomInputLabel><Popup
                            content={explanatoryText}
                            trigger={
                                <SemanticFeatherIcon FeatherIcon={HelpCircle} size="32px" centerIcon />

                            } /></FlexBox> : <label>{label}</label>}
                        value={value !== null && value !== undefined ? value : ""}
                        required={required}
                        onChange={(e, { value }) => overrideOnChange ? overrideOnChange(value) : onChange(value)}
                        disabled={disabled}
                        type={type}
                        placeholder={placeholder}
                        error={!!error && { content: t(`${error.message}`), }}
                    />
                )
            }}
        />
    )
}
export default ControlInput