import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Tab, TabPane } from 'semantic-ui-react';
import ChangePassword from './ChangePassword';
import ClothesSize from './UserClothesSize';
import InCaseOfEmergency from './UserIce'
import MySkills from '../skills/mobile/mySkils';

const UserInfo = () => {
   
    const { t } = useTranslation();

    const panes = useMemo(() => [
        { menuItem: t('mySkills'), render: () =><TabPane><MySkills /></TabPane> },
        { menuItem: t('clothesSizeFormHeader'), render: () =><TabPane><ClothesSize /></TabPane> },
        { menuItem: t('iceFormHeader'), render: () =><TabPane><InCaseOfEmergency /></TabPane> },
        { menuItem: t('userAuthFormHeader'), render: () =><TabPane><ChangePassword /></TabPane> }, 
    ], [t]);

    return <Container><Tab panes={panes} /></Container>

};

export default UserInfo;