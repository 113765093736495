import React from "react"
import { Dropdown, GridColumn, GridRow } from "semantic-ui-react"
import { IDropdownOption } from "../../../../../fields/ControlDropdown";
import { getState } from "../../../../../../state";
import { useTranslation } from "react-i18next";
import * as API from '../../../../../../api'
import { mergeSubRows } from "../../../../../../utils/Mappings/Issues";

interface IDeviationStatsticFiltersProps {
    types: API.issues.IssueTypeHierarchy[]
    selectedYears: number[]
    selectedMonths: number[]
    selectedDepartments: number[]
    selectedLocations: number[]
    selectedTypes: number[]
    selectedErrorCodes: number[]
    selectedCauseCodes: number[]
    selectedStats: number[]
    setSelectedYears: React.Dispatch<React.SetStateAction<number[]>>
    setSelectedMonths: React.Dispatch<React.SetStateAction<number[]>>
    setSelectedDepartments: React.Dispatch<React.SetStateAction<number[]>>
    setSelectedLocations: React.Dispatch<React.SetStateAction<number[]>>
    setSelectedTypes: React.Dispatch<React.SetStateAction<number[]>>
    setSelectedErrorCodes: React.Dispatch<React.SetStateAction<number[]>>
    setSelectedCauseCodes: React.Dispatch<React.SetStateAction<number[]>>
    setSelectedStats: React.Dispatch<React.SetStateAction<number[]>>
    handleDropdownChange: (value: number[], selectedState: number[], setState: React.Dispatch<React.SetStateAction<number[]>>, options: IDropdownOption[]) => void
}

const DeviationStatisticFilters = ({
    types,
    selectedYears,
    selectedMonths,
    selectedDepartments,
    selectedLocations,
    selectedTypes,
    selectedErrorCodes,
    selectedCauseCodes,
    selectedStats,
    setSelectedYears,
    setSelectedMonths,
    setSelectedDepartments,
    setSelectedLocations,
    setSelectedTypes,
    setSelectedErrorCodes,
    setSelectedCauseCodes,
    setSelectedStats,
    handleDropdownChange }: IDeviationStatsticFiltersProps) => {
    const {
        state: { locations, departments },
    } = getState()
    const { t } = useTranslation()



    const currentYear = new Date().getFullYear();

    const selectAllOption: IDropdownOption = { value: 0, text: t('selectAll') }
    const years = [selectAllOption];
    const months = [selectAllOption];

    for (let year = 2011; year <= currentYear; year++) {
        years.push({ value: year, text: String(year) });
    }

    for (let i = 0; i < 12; i++) {
        months.push({ value: i + 1, text: t(`month.${i + 1}`) });
    }

    const departmentOptions = [selectAllOption, ...departments?.map(d => ({ value: d.id, text: d.name }))] ?? []
    const locationOptions = locations && locations.length ? [selectAllOption, ...locations?.map(d => ({ value: d.id, text: d.name }))] : []
    const statsOptions = [selectAllOption,
        ...[{ value: 1, text: t("table") }, { value: 2, text: t("barChart") },
        { value: 3, text: t("remedyEffecieny") },
        { value: 4, text: t("deviationsReportedInTime") }, { value: 5, text: t("qualityDeviationCost") }]]
    const typeOptions = types && types.length ? [selectAllOption, ...types?.map(d => ({ value: d.id, text: d.name }))] : []

    const mergedErrorCodes = types && types.length ? mergeSubRows(types, selectedTypes) : []
    const errorCodeOptions = types && types.length ? [selectAllOption, ...mergedErrorCodes?.map(d => ({ value: d.id, text: d.name }))] : []

    const mergedCauseCodes = mergedErrorCodes && mergedErrorCodes.length ? mergeSubRows(mergedErrorCodes, selectedErrorCodes) : []
    const causeCodeOptions = types && types.length ? [selectAllOption, ...mergedCauseCodes?.map(d => ({ value: d.id, text: d.name }))] : []

    return (
        <>
            <GridRow columns={4}>
                <GridColumn>
                    <label>{t('statisticType')}</label>
                    <Dropdown
                        options={statsOptions}
                        value={selectedStats}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedStats, setSelectedStats, statsOptions)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn>
            </GridRow>
            <GridRow columns={2}>
                <GridColumn>
                    <label>{t('captionYear')}</label>
                    <Dropdown
                        options={years.sort((a, b) => Number(b.value) - Number(a.value))}
                        value={selectedYears}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedYears, setSelectedYears, years)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn>
                <GridColumn>
                    <label>{t('month')}</label>
                    <Dropdown
                        options={months}
                        value={selectedMonths}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedMonths, setSelectedMonths, months)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn>
            </GridRow>
            <GridRow columns={3}>
                {types && types.length ? <GridColumn>
                    <label>{t('issueType')}</label>
                    <Dropdown
                        options={typeOptions}
                        value={selectedTypes}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedTypes, setSelectedTypes, typeOptions)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn> : null}
                <GridColumn>
                    <label>{t('departmentId')}</label>
                    <Dropdown
                        options={departmentOptions}
                        value={selectedDepartments}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedDepartments, setSelectedDepartments, departmentOptions)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn>
                {locations && locations.length ? <GridColumn>
                    <label>{t('locationId')}</label>
                    <Dropdown
                        options={locationOptions}
                        value={selectedLocations}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedLocations, setSelectedLocations, locationOptions)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                    />
                </GridColumn> : null}
            </GridRow>
            <GridRow columns={2}>
                {types && types.length ? <GridColumn>
                    <label>{t('errorCode')}</label>
                    <Dropdown
                        options={errorCodeOptions}
                        value={selectedErrorCodes}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedErrorCodes, setSelectedErrorCodes, errorCodeOptions)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                        disabled={selectedTypes.length === 0}
                    />
                </GridColumn> : null}
                {types && types.length ? <GridColumn>
                    <label>{t('causeCode')}</label>
                    <Dropdown
                        options={causeCodeOptions}
                        value={selectedCauseCodes}
                        onChange={(_, x) => handleDropdownChange(x.value as number[], selectedCauseCodes, setSelectedCauseCodes, causeCodeOptions)}
                        fluid
                        multiple
                        search
                        selection
                        clearable
                        disabled={selectedErrorCodes.length === 0}
                    />
                </GridColumn> : null}
            </GridRow>
        </>

    )
}
export default DeviationStatisticFilters