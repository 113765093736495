import { z } from "zod";
import * as API from '../../../api';
import { IDepartmentDto } from "../../../api/admin";
import { useTranslation } from "react-i18next";
import { getState } from "../../../state";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { handleAxiosError } from "../../../actions/error";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { Form, Grid, GridColumn, GridRow } from "semantic-ui-react";
import ControlInput from "../../fields/ControlInput";
import React from "react";
import ControlTextArea from "../../fields/ControlTextArea";
import FormActions from "../FormActions";

interface DepartmentFormProps {
    selectedDepartment: IDepartmentDto | null;
    refresh: () => void;
    onClose: () => void;
}

const DepartmentListSchema = z.object({
    name: z.string().optional(),
    description: z.string().optional(),
});

const DepartmentForm : React.FC<DepartmentFormProps> = ({ selectedDepartment, refresh, onClose }) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    
    const { control, handleSubmit } = useForm<IDepartmentDto>({
        resolver: zodResolver(DepartmentListSchema),
        defaultValues: selectedDepartment ? selectedDepartment: { name: "", description: "" }
    });

    const onDepartmentRequest = async (data: IDepartmentDto) => {
        if (selectedDepartment?.id) {
            return await API.admin.adminUpdateDepartment(selectedDepartment.id, data);
        } else {
            return await API.admin.createDepartment(data);
        }
    };

    const { isLoading: isSaving, mutate } = useMutation(onDepartmentRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch) (error);
        },
        onSuccess: () => {
            toast.success(t('saved'));
            refresh();
            onClose();
        },
    });

    return (
        <Form onSubmit={handleSubmit((data) => mutate(data))} noValidate>
            <Grid stackable>
                
                <GridRow columns="1">
                    <GridColumn>
                        <ControlInput name="name" label={t('department')} placeholder={t('department')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <ControlTextArea name="description" label={t('description')} placeholder={t('description')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <FormActions onCancel={onClose} disabled={isSaving}/>
                    </GridColumn>
                </GridRow>

            </Grid>
        </Form>
    )
};

export default DepartmentForm;