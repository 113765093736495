import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FullHeightStackingContainer from '../../../common/fullHeightStackingContainer'
import Loading from '../../../common/Loading'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Edit, Trash2 } from 'react-feather'
import { useDeleteRisk } from './hooks/useDeleteRisk'
import { Button, Checkbox, Header } from 'semantic-ui-react'
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    ColumnDef,
    GroupingState,
    getGroupedRowModel,
    VisibilityState,
    PaginationState,
    ColumnFiltersState,
    SortingState,
    ExpandedState,
    getExpandedRowModel,
} from '@tanstack/react-table'
import TableReact from '../../../wrappers/TableReact'
import DateCell from '../../../common/cells/DateCell'
import { useNavigate } from 'react-router-dom'
import { formatDate } from '../../../../actions/util'
import useTableStorageBackedState from '../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { useIsInRole } from '../../../../hooks/role'
import { AdminRoles } from '../../../../auth/roles'
import { globalFilterFn } from '../../../wrappers/TableReact/Filters/GlobalFilter'
import { DateRangeFilter, dateBetweenFilterFn } from '../../../wrappers/TableReact/Filters/DateRangeFilter'
import EmptyRequiredCollection from '../../../common/EmptyRequiredCollection'
import { IRiskLimitDto, IRiskListResponse, IRiskTypeDto } from '../../../../api/risks'
import RiskValueTable from './RiskValueTable'
import RiskValueCell from '../../../common/cells/RiskValueCell'
import { RiskRangeFilter, riskRangeFilterFn } from '../../../wrappers/TableReact/Filters/RiskRangeFilter'

interface IRiskListProps {
    risks: IRiskListResponse[]
    limits?: IRiskLimitDto
    riskTypes: IRiskTypeDto[]
    isLoading: boolean
    refresh: () => void
}
const RiskList = ({ risks, limits, riskTypes, isLoading, refresh }: IRiskListProps) => {
    const { t } = useTranslation()

    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('riskColumnVisibility', {});
    const [grouping, setGrouping] = useTableStorageBackedState<GroupingState>('riskGroupingState', []);
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('riskPaginationState', { pageSize: 10, pageIndex: 0 });
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('riskFilteringState', []);
    const [globalFilter, setGlobalFilter] = useTableStorageBackedState('riskGlobalFilterState', "");
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('riskSortingState', []);
    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("riskExpandedState", {})
    const [showAll, setShowAll] = useTableStorageBackedState('riskListShowAllState', false);

    const { isInRole: isAdmin } = useIsInRole(AdminRoles.RisksAdminRole)
    const navigate = useNavigate()
    const { isDestroying, onDestroy } = useDeleteRisk(refresh)
    const columnHelper = createColumnHelper<IRiskListResponse>()
    const columnsReact: ColumnDef<IRiskListResponse, any>[] = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 95,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' onClick={() => navigate(`./detail/${row.original.id}`)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        {isAdmin && <Button
                            title={t('delete')}
                            type='button'
                            color='red'
                            onClick={() => onDestroy({ confirmText: t('confirm.deleteValue', { value: row.original.risk?.split(" ").slice(0, 5).join(" ") }), riskId: row.original.id })}
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />}
                    </>
            }),
            columnHelper.accessor("riskTypeName", {
                id: "riskTypeName",
                header: t('riskTypeName'),
            }),
            columnHelper.accessor("documentName", {
                id: "documentName",
                header: t('documentName'),
            }),
            columnHelper.accessor("apt", {
                id: "apt",
                header: t('apt'),
            }),
            columnHelper.accessor("risk", {
                id: "risk",
                header: t('risk'),
                size: 500
            }),
            columnHelper.accessor("impact", {
                id: "impact",
                header: t('impact'),
            }),
            columnHelper.accessor("measurment", {
                id: "measurment",
                header: t('measurment'),
            }),
            columnHelper.accessor("comment", {
                id: "comment",
                header: t('comment'),
            }),
            columnHelper.accessor(r => {
                const consequence = Number(r.consequence);
                return isNaN(consequence) ? 0 : consequence;
            }, {
                id: "consequence",
                header: t('consequence'),
                filterFn: 'weakEquals',
                meta: { checkbox: true },
            }),
            columnHelper.accessor(r => {
                const probability = Number(r.probability);
                return isNaN(probability) ? 0 : probability;
            }, {
                id: "probability",
                header: t('probability'),
                filterFn: 'weakEquals',
                meta: { checkbox: true },
            }),
            columnHelper.accessor(r => {
                const consequenceProbabilityValue = Number(r.consequenceProbabilityValue);
                return isNaN(consequenceProbabilityValue) ? 0 : consequenceProbabilityValue;
            }, {
                id: "consequenceProbabilityValue",
                header: t('consequenceProbabilityValue'),
                filterFn: riskRangeFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: number[]) => (
                        <RiskRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <RiskValueCell value={row.original.consequenceProbabilityValue} limits={limits} />
            }),
            columnHelper.group({
                id: 'handledVia',
                header: t('handledVia'),
                columns: [
                    columnHelper.accessor('hasGoal', {
                        id: "hasGoal",
                        header: t('hasGoal'),
                        meta: { checkbox: true },
                        cell: ({ row }) => <Checkbox checked={!!row.original.hasGoal} />
                    }),
                    columnHelper.accessor("hasActionPlan", {
                        id: "hasActionPlan",
                        header: t('hasActionPlan'),
                        meta: { checkbox: true },
                        cell: ({ row }) => <Checkbox checked={!!row.original.hasActionPlan} />
                    }),
                    columnHelper.accessor("hasRoutine", {
                        id: "hasRoutine",
                        header: t('hasRoutine'),
                        meta: { checkbox: true },
                        cell: ({ row }) => <Checkbox checked={!!row.original.hasRoutine} />
                    }),
                ],
            }),
            columnHelper.accessor(r => r.latestChange ? formatDate(r.latestChange) : "", {
                id: "latestChange",
                header: t('latestChange'),
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.latestChange} />
            }),
        ], [columnHelper, navigate, onDestroy, t, isAdmin, limits]
    );

    const table = useReactTable({
        data: risks || [],
        columns: columnsReact,
        columnResizeMode: "onChange",
        state: {
            columnVisibility,
            grouping,
            pagination,
            columnFilters,
            globalFilter,
            sorting,
            expanded
        },
        initialState: {
            globalFilter: "",
            columnFilters: [],
            grouping: [],
            sorting: [{ id: "consequenceProbabilityValue", desc: false }],
        },
        globalFilterFn: globalFilterFn,
        onGroupingChange: setGrouping,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        onExpandedChange: setExpanded,
        getGroupedRowModel: getGroupedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        onPaginationChange: setPagination
    })
    if (isLoading || isDestroying) {
        return <Loading />
    }
    return (
        <FullHeightStackingContainer>
            <Header dividing>{t('risks')}</Header>
            <RiskValueTable />
            <EmptyRequiredCollection departmentsRequired={false} riskTypes={riskTypes} riskLimitsRequired riskLimits={limits} />
            <TableReact
                table={table}
                onAddCallback={() => navigate("./detail/new")}
                canExport
                overrideShowAllTitle={t('showBigRiskOpportunity')}
                showAllFilter={{ showAll, setShowAll, checked: [{ id: "consequenceProbabilityValue", value: [limits?.negative, limits?.positive] }], notChecked: [{ id: "consequenceProbabilityValue", value: [] }] }}
            />
        </FullHeightStackingContainer>
    )
}
export default RiskList
