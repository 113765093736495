import React, { useEffect } from "react";
import { Button, Form, Grid, GridColumn, GridRow } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import SemanticFeatherIcon from "../../icons/SemanticFeatherIcon";
import { Save } from "react-feather";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { handleAxiosError } from "../../../actions/error";
import * as API from '../../../api';
import { getState } from "../../../state";
import { IUserIce } from "../../../api/admin";
import { z } from "zod";
import ControlTextArea from "../../fields/ControlTextArea";

interface IceFormProps {
    userIce: IUserIce | null;
    refresh: () => void;
    onClose?: () => void;
}
const userIceSchema = z.object ({
    id: z.number().optional(),
    userIce1: z.string().optional(),
    userIce2: z.string().optional()
});

const IceForm : React.FC<IceFormProps> = ({ userIce, refresh, onClose }) => {
    const { t } = useTranslation();
    const {
            dispatch,
        } = getState()

        const { control, handleSubmit, reset } = useForm<IUserIce>({
            resolver: zodResolver(userIceSchema),
        })
    
        useEffect(() => {
            if (userIce) {
                reset({ 
                    userIce1: userIce.userIce1 ?? "",
                    userIce2: userIce.userIce2 ?? ""
                });
           
            } else {
                reset({ userIce1: "", userIce2: "" });
            }
        }, [userIce, reset]);
    
    const onUserIceRequest = async (data: IUserIce) => {
        if (userIce?.userId) {
            return await API.admin.adminUpdateUserIce(userIce.userId, data)
        } else {
            return await API.admin.createIce(userIce?.userId, data)
        }
    };

     const { isLoading: isSaving, mutate: onSubmit } = useMutation(onUserIceRequest, {
            onError: (error: any) => {
                handleAxiosError(dispatch)(error);
            },
            onSuccess: () => {
                toast.success(t('saved'));
                refresh();
                if (onClose) onClose();
            },
        });

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
        <Grid stackable>

            <GridRow columns="1">
                <GridColumn>
                    <ControlTextArea name="userIce1" label={t('userIce1')} placeholder='Namn, relation och telefonnummer' control={control} disabled={isSaving} />
                </GridColumn>
            </GridRow>

            <GridRow columns="1">
                <GridColumn>
                    <ControlTextArea name="userIce2" label={t('userIce2')} placeholder='Namn, relation och telefonnummer' control={control} disabled={isSaving} />
                </GridColumn>
            </GridRow>

            <GridRow columns="1" textAlign='right'>
               <GridColumn >
                    <Button
                        type="submit" 
                        primary
                        disabled={false}
                        icon 
                        labelPosition='left'>
                        <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon />
                        {t('save')}
                    </Button>
               </GridColumn>
            </GridRow>

        </Grid>
    </Form>
    );
};

export default IceForm;