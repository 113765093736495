import React from 'react'
import { Container } from 'semantic-ui-react'
import Loading from '../../../common/Loading'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams';
import * as API from '../../../../api'
import ClothesSizeForm from '../../../forms/ClothesSizeForm';

const UserClothesSize = () => {

    const {
        isLoading,
        data: clothesSize,
        refresh,
    } = useGetQueryByParams(true, API.admin.getMyClothesSize, "clothesSize");


    if (isLoading || !clothesSize) {
        return <Loading />
    }

    return (
        <Container>
            <ClothesSizeForm selectedUser={clothesSize} refresh={refresh} />
        </Container>
    );
};

export default UserClothesSize;